import React from 'react'
import { Carousel } from 'antd';
import { Link } from "react-router-dom";
import AppTitle from '../../../shared/appTitle';
const QuickLinks = () => {
  return (
    <div className='quick_payment'>
      <Carousel autoplay>
        <div >
          <div className='quickpay-carsl'>
            <Link to="/buy"> <div className='text-center customicon c-pointer'>
              <div className='icon-circle'><span className='icon xxl cart'></span></div>
              <AppTitle level={5} className='text-hover'>Buy</AppTitle>
            </div></Link>
            <Link to="/sell"><div className='text-center customicon c-pointer'>
              <div className='icon-circle'><span className='icon xxl sell'></span></div>
              <AppTitle level={5} className='text-hover'>Sell</AppTitle>
            </div></Link>
            <Link to={process.env.REACT_APP_WALLET_TYPE === 'non_custodial'? "/deposit/crypto" :"/deposit/fiat"  }><div className='text-center customicon c-pointer'>
              <div className='icon-circle'> <span className='icon xxl uparrow'></span></div>
              <AppTitle level={5} className='text-hover'>Deposit</AppTitle>
            </div></Link>
            <Link to={process.env.REACT_APP_WALLET_TYPE === 'non_custodial'? "/withdraw/crypto" :"/withdraw/fiat"  }><div className='text-center customicon c-pointer'>
              <div className='icon-circle' style={{ margin: 'auto' }}><span className='icon xxl downarrow'></span></div>
              <AppTitle level={5} className='text-hover'>Withdraw</AppTitle>
            </div></Link>
          { <Link to={process.env.REACT_APP_WALLET_TYPE !== 'non_custodial'?"/payees/fiat": "/payees/crypto"}> <div className='text-center customicon c-pointer'>
              <div className='icon-circle'> <span className='icon xxl wallet'></span></div>
              <AppTitle level={5} className='text-hover'>Payees</AppTitle>
            </div></Link>}
          </div>
        </div>
      </Carousel>
    </div>
  )
}
export default QuickLinks