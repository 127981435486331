import { Table, Empty, Drawer, Typography,Alert,Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { detailInfoColumns, infoColumns } from './marketcap.columns';
import { connect } from 'react-redux';
import { fetchMarketCaps } from '../api';
import { isErrorDispaly } from "../../../neo/components/validationsAndExceptions/apiExceptions";

const { Text } = Typography;
const { Search } = Input;
const MarketCap = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [marketCaps, setMarketCaps] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [searchVal, setSearchVal] = useState([])
    const [originalMarketCaps, setOriginalMarketCaps] = useState([]);
    const [fullViewData, setFullViewData] = useState([]);
    const [errorMessage,setErrorMessage]=useState(null)
    useEffect(() => {
        fetchMarketCapsInfo();
    }, [])
    const fetchMarketCapsInfo = async () => {
        setErrorMessage(null)
        setIsLoading(true);
        setSearchVal("");
        try{
            const response = await fetchMarketCaps({ pageNo: '1' });
            if (response.ok) {
                setMarketCaps(response.data);
                setOriginalMarketCaps(response.data);
                setErrorMessage(null);
            } else {
                setErrorMessage(isErrorDispaly(response));
                setMarketCaps([]);
            }
        } catch (error) {
            setErrorMessage(isErrorDispaly(error));
            setMarketCaps([]);
        } finally {
            setIsLoading(false);
        }
    }
    const onSearch = ({ currentTarget: { value } }, isFullScreen) => {
        let matches = originalMarketCaps.filter(item => item.symbol.toLowerCase().includes(value.toLowerCase()));
        setSearchVal(value)
        if (!isFullScreen) { setMarketCaps(matches) } else {
            setFullViewData(matches);
        }
    }
    const onClose = () => {
        setSearchVal("");
        setMarketCaps([...originalMarketCaps]);
        setFullViewData([]);
        setIsOpen(false)
    }
    return <>
     {errorMessage !== undefined && errorMessage !==null && errorMessage !=="" && (
                <div style={{ width: '100%' }}>
                <Alert className="w-100 mb-16 align-center" type="error" showIcon description={errorMessage} />
                </div>
             )} 
        <div className='market-panel-newstyle'></div>
            <div className="full-screenable-node marketcap-mt" >

        <div className="d-flex align-center market-flex jusity-content-spacebetween">
        <Text className="card-title ordrhstry-title">Markets</Text>
        <div className="coin-search dt-topspace">
                <Search value={searchVal} placeholder='Search Coin' suffix={<span className="icon md search c-pointer" />}
                onChange={(value) => onSearch(value)} size="middle" bordered={false} className="coin-search-input " />
            </div>
                    </div>

                <div className='bash-market-table responsive_table bg-none dashb-btmtable'>


                <Table  locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No Data"} /> }} sortDirections={["ascend", "descend"]}  scroll={{ y: '' }} pagination={false} columns={infoColumns} dataSource={marketCaps} loading={isLoading} className="pay-grid view mb-view marketcap-coinsize"  rowKey="id"/>
                </div>
            </div>
            <Drawer
                title={<div className="side-drawer-header">
                    <Text className="grid-title">Markets</Text>
                    <span onClick={() => onClose()} className="icon md close-white c-pointer" />
                </div>}
                placement="right"
                width="100%"
                closable={true}
                visible={isOpen}
                closeIcon={null}
                onClose={() => setIsOpen(false)}
                className="side-drawer-full markets-drawer"
                destroyOnClose={true}
            >
                <div className="markets-panel mr-0 markets-popup">
                    <div className="full-screenable-node" >

                        <div style={{ marginBottom: '8px', textAlign: 'right' }}>
                            <Table className='markets-grid' locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No Data"} /> }} sortDirections={["ascend", "descend"]}  pagination={false} columns={detailInfoColumns}  dataSource={fullViewData}  />
                        </div>
                    </div>
                </div>
                </Drawer>
    </>
}
const connectStateToProps = ({ userConfig }) => {
    return { member: userConfig.userProfileInfo }
}
export default connect(connectStateToProps, null)(MarketCap);