import { apiClient,web3Client } from '../../api/clients';
import { ApiControllers } from '../../api/config';
const walletType=process.env.REACT_APP_WALLET_TYPE
const getNotifications = (customerId,appName) => {
    if(walletType !== "non_custodial"){
        return apiClient.get(ApiControllers.notification + `Notifications/${customerId}/${appName}`);
    }else{
        return web3Client.get(ApiControllers.notification + `Notifications/${customerId}/${appName}`); 
    }
}
const readNotification = (customerId,obj,appName) => {
    if(walletType !== "non_custodial"){
        return apiClient.put(ApiControllers.notification + `UpdateReadCount/${customerId}/${appName}`,obj);
    }else{
        return web3Client.put(ApiControllers.notification + `UpdateReadCount/${customerId}/${appName}`,obj);

    }
}

export { getNotifications, readNotification}