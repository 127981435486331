const UPDATE_PERMISSIONS = "updatePermissions";
const CLEAR_PERMISSIONS = "clearPermissions";
const SET_SELECTED_FEATUREID = "setSelectedFeatureMenu";
const UPDATE_ACCESSDENIED = "updateAccessdenied";

const clearPermissions = () => {
    return {
        type: CLEAR_PERMISSIONS,
    }
}
const updatePermissions = (payload) => {
    return {
        type: UPDATE_PERMISSIONS,
        payload
    }
}
const setSelectedFeatureMenu = (payload) => {
    return {
        type: SET_SELECTED_FEATUREID,
        payload
    }
}
const updateAccessdenied = (payload) => {
    return {
        type: UPDATE_ACCESSDENIED,
        payload
    }
}

const initialState = {
    features: { loading: true, data: [], error: null },
    featurePermissions: { loading: true, data: [], error: null,selectedScreenFeatureId: null  },
    accessDenied:false
}
const featuresReducer = (state , action) => {
    state = state || initialState

    switch (action.type) {
        case SET_SELECTED_FEATUREID:
            state = { ...state, featurePermissions: { ...state.featurePermissions, selectedScreenFeatureId: action.payload,  } };
            return state;
        case UPDATE_ACCESSDENIED:
            state = { ...state, accessDenied:action.payload  };
            return state;
        case CLEAR_PERMISSIONS:
            state = { ...state, featurePermissions: { data: [], error: null, loading: true,selectedScreenFeatureId: null } };
            return state;
        default:
            return state;
    }
}

export default featuresReducer;
export {  clearPermissions, updatePermissions, setSelectedFeatureMenu, updateAccessdenied };