const WALLET_TYPE = 'non_custodian';
export const MenuList = [
   {
      "title": "Dashboard",
      "path": "/dashboard",
      "activePath":"dashboard",
   },
   {
      "title": "Buy",
      "path": "/buy",
      "activePath":"buy",
   },
   {
      "title": "Sell",
      "path": "/sell",
      "activePath":"sell",
   },
   {
      "title": "Deposit",
      "path": "/deposit/fiat",
      "activePath":"deposit",
   },
   {
      "title": "Withdraw",
      "path": "/withdraw/fiat",
      "activePath":"withdraw",
   },
   {
      "title": "Transactions",
      "path": "/transactions",
      "activePath":"transactions",
   },
   {
      "title": "Payees",
      "path": "/payees/fiat",
      "activePath":"payees/",
   }
]
export const nonCustodialMenuList = [
   {
      "title": "Dashboard",
      "path": "/dashboard",
      "activePath":"dashboard",
   },
   {
      "title": "Buy",
      "path": "/buy",
      "activePath":"buy",
   },
   {
      "title": "Sell",
      "path": "/sell",
      "activePath":"sell",
   },
   {
      "title": "Deposit",
      "path": "/deposit/crypto",
      "activePath":"deposit",
   },
   {
      "title": "Withdraw",
      "path": "/withdraw/crypto",
      "activePath":"withdraw",
   },
   {
      "title": "Transactions",
      "path": "/transactions",
      "activePath":"transactions",
   },
   {
      "title": "Payees",
      "path": "/payees/crypto",
      "activePath":"payees/",
   }
]