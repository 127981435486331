import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from "moment/moment";
import apicalls from "../api/apiCalls";
import { Empty, Skeleton } from 'antd'
import { isErrorDispaly } from '../neo/components/validationsAndExceptions/apiExceptions';
import Loader from "../shared/loader";
import Alert from 'antd/lib/alert';
import AppTitle from '../shared/appTitle';
import { connect } from 'react-redux'
import { withRouter, useRouteMatch } from 'react-router-dom';
import { fetchTransactionDetails } from '../reducers/transactionGridReducer';
const walletType=process.env.REACT_APP_WALLET_TYPE;
function OrderGrid(props) {
  const [gridData, setGridData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [toggleIcon, setToggleIcon] = useState(false);
  const [loader, setLoader] = useState(false)
  const { url } = useRouteMatch();
  const totalFiatGridCount = 300;
  const pageSize = 10;
  let pageNo = Math.ceil((gridData?.length||0) / pageSize) + 1;
  useEffect(()=>{
    if(url==="/dashboard" ||url==="/"){
      props.dispatch(fetchTransactionDetails(props?.apiMethod,pageNo, pageSize));}
  },[props.apiMethod, pageNo, pageSize])
  useEffect(() => {
    setGridData(props?.transactionGrid?.transactioListData?.data);
  }, [props?.transactionGrid?.transactioListData?.data]);
  const fetchMoreData = () => {
    if (toggleIcon && (gridData?.data?.length < totalFiatGridCount)) {
      orderHistoryGrid();
    }
  }
  const orderHistoryGrid = async () => {
    setLoader(true);
    try {
      const response = await apicalls.getOrderHistoryGrid(props?.apiMethod, pageNo, pageSize);
      if (response.ok) {
        const mergeData = [...gridData, ...response?.data?.data];
        setGridData(mergeData);
      } else {
        setErrorMessage(isErrorDispaly(response));
      }
    } catch (error) {
      setErrorMessage(isErrorDispaly(error));
    } finally {
      setLoader(false);
    }
  }


  const handleRedirectTrue = () => {
    setToggleIcon(true)
    props.showOrderHistory(true)
    props.history.push("/transactions")
  }
  const handleRedirectFalse = () => {
    setToggleIcon(false)
    props.showOrderHistory(false)
  }
  const handleTransaction = () => {
    props.history.push("/transactions")
  }

  const btnColourList = {
    Success: "table-text td-success",
    Submitted: "table-text td-success",
    Approved: "table-text td-success",
    Cancelled: "table-text text-red",
    Rejected: "table-text text-red",
    Pending: "table-text text-blue"
  }

  const showTransactions = (index) => {
    return !toggleIcon ? index < 5 : index
  }

  return (
    <>
       {gridData===undefined&&props?.transactionGrid?.transactioListData?.error !== undefined && props?.transactionGrid?.transactioListData?.error !== null && (
        <div className="alert-flex" style={{ width: "100%" }}>
          <Alert
            className="w-100 "
            type="warning"
            description={props?.transactionGrid?.transactioListData?.error}
            showIcon
          />
          <span className="icon sm alert-close" onClick={() => errorMessage(null)}></span>
        </div>
      )}

      <div className='d-flex align-center order-title justify-content-between'>
        <div className='d-flex align-center order-hist-margin justify-content-between flex-1'>
          <AppTitle className='card-title ordrhstry-title'>{props.srceenType==="dashboard" ? "Transactions" : "Order History"} </AppTitle>
         <div>
            {props.srceenType==="dashboard" &&
            <div onClick={handleTransaction} className='d-flex align-items-center'>
              <span className='text-link c-pointer view-more'>View More</span>
              <span className="icon lg square-arrow c-pointer" ></span>
              </div>

            }{props.srceenType!="dashboard" && <>
              {!toggleIcon && <span className='icon lg square-arrow c-pointer' onClick={handleRedirectTrue}></span>}
              {toggleIcon && <span className='icon md down-arrow c-pointer icon-down' onClick={handleRedirectFalse}></span>}
            </>
            }
            </div>
          </div>
      </div>
      <InfiniteScroll
        dataLength={gridData?.length}
        className='db-table'
        next={fetchMoreData}
        hasMore={toggleIcon && gridData?.length < totalFiatGridCount}
        loader={
          (props?.transactionGrid?.transactioListData?.loading || loader) && <Skeleton
            avatar
            paragraph={{
              rows: 1,
            }}
            active
          />
        }
        endMessage={
          (toggleIcon && (gridData?.length > totalFiatGridCount)) && <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        <table style={{width:"100%",minWidth: 850}} className='orderhistory-table'>
        {gridData?.data?.map((item, index) => {
          return <>
            {showTransactions(index) && <tr key={item?.txId || index} className="custom-table card">

             
              <td className="" width={210}><label className="table-label">Transaction ID</label><AppTitle level={4} className="table-text">{item?.txId ? item?.txId : "-"}</AppTitle></td>
                <td className="" width={220}><label className="table-label">Date</label><AppTitle level={4} className="table-text">
                  {item?.date ? <>{moment.utc(item?.date).local().format("DD/MM/YYYY hh:mm:ss A")}</> : item?.date}
                </AppTitle></td>
                
              {item?.txType&&<td className="" width={180}><label className="table-label">Transaction Type</label><AppTitle level={4} className="table-text">{item?.txType ? item?.txType : "-"}</AppTitle></td>}
              {props.srceenType==="dashboard"&&<td className=""><label className="table-label">Type</label><AppTitle level={4} className="table-text">{item?.type ? item?.type : "-"}</AppTitle></td>}
              <td className=""><label className="table-label">Wallet</label><AppTitle level={4} className="table-text">{item?.walletCode ? item?.walletCode : "-"}</AppTitle></td>
              <td className=""><label className="table-label">Value</label><AppTitle level={4} className="table-text">{item.amount}
              </AppTitle></td>
              <td className="" width={150}><label className="table-label">Status</label><AppTitle level={4}
                className={btnColourList[item.state === "Approved (Sent)" && "Approved" || item.state]}
              >{item?.state ? item?.state : "-"}</AppTitle></td>
              {(props.srceenType=="withdraw"||props.srceenType=="deposit") && walletType!=='non_custodial' && 
              <td className=""><label className="table-label">Reason for Rejection</label><AppTitle level={4} className="table-text rejection-description">{item.reasonForRejection||"--"}
              </AppTitle></td> }
            </tr>}
          </>
        })}
        </table>
        {!(gridData?.data?.length > 0 || gridData?.data == undefined) && !(props.transactionGrid?.transactioListData?.loading || loader) && (
          <div>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Data"
            ></Empty></div>)
        }

        {(props.transactionGrid?.transactioListData?.loading || loader) && (gridData?.data?.length > 0 || gridData?.data === undefined)
          &&
          <Loader spinning={props.transactionGrid?.transactioListData?.loading || loader} />}
      </InfiniteScroll>
    </>
  )
}
const connectStateToProps = ({ userConfig, transactionGrid }) => {
  return { userConfig: userConfig.userProfileInfo, transactionGrid }
}
const connectDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(connectStateToProps, connectDispatchToProps)(withRouter(OrderGrid));