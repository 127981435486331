import { Component } from 'react'
import { Layout, Alert } from 'antd';
import RouteConfig from './routeConfig';
import ConnectStateProps from '../utils/connectStateProps';
import { withRouter } from "react-router-dom";
import { checkCustomerState } from '../utils/service'
const { Content } = Layout;
class AppContent extends Component {
  componentDidMount() {
    if (process.env.REACT_APP_WALLET_TYPE === "custodial") {
      if (!this.props.userProfile?.isEmailVerified) {
        this.props.history.push('/emailVerification');
      }
      else if (!this.props.userProfile?.isCustomerUpdated) {
        this.props.history.push('/auth0');
      } else if (!this.props.userProfile?.isPhoneNumberVerified) {
        this.props.history.push('/phoneVerification');
      }
      else if (!this.props?.userProfile?.isKYC) {
        this.props.history.push('/sumsub');
      }
      else if (this.props.userProfile?.isKYC && !checkCustomerState(this.props.userProfile)) {
        this.props.history.push("/accountstatus")
      } else {
        this.props.history.push("/dashboard")
      }
    }
  }

  render() {
    return <div className={` ${this.props.location.pathname.includes("/welcome") ? "" : "main-container container-topspace"}`}>
      {this.props.serviceWReducer?.isUpdateAvailable && <Alert showIcon
        message="App Update"
        description="New app updates available. Please close all browser tabs & re-open app for seemless experience"
        type="warning"
      // action={
      //   <Space direction="vertical">
      //     <Button size="small" type="primary" className='primary-btn pop-btn' onClick={()=>window.location.reload()}>
      //       Refresh
      //     </Button>
      //   </Space>
      // }
      />}
      <Content>
        <RouteConfig />
      </Content>
    </div>
  }
}

export default ConnectStateProps(withRouter(AppContent));