import {getFiatCoins, getCryptoCoins, getCryptoWallets, getNetworkLu } from "./api";
import { isErrorDispaly } from "../../neo/components/validationsAndExceptions/apiExceptions";

const GET_CRYPTOCOINS = "getCryptoCoinsList"
const GET_FIATCOINS = "getFiatCoinsList";
const UPDATEASSETS = "updateAssets"
const SET_ASSET = 'setAssetCoin';
const SET_ADDRESS = 'setAddress'
const SELECTED_WITHDRAWDATA = 'selectedWithdarwData'
const SELECTED_WALLET_NETWORK = 'selectedWalletNetwork'
const SELECTED_WALLET_NETWORKDATA = 'selectedWalletNetworkData'
const FETCH_NETWORKS = 'fetchNetworksData';
const SET_SELECTED_NETWORK = "setselectedNetwork";
const SET_ADDRESSLOADING = "setAddressLoading";

const getCryptoCoinsList = (payload) => {
    return {
        type: GET_CRYPTOCOINS,
        payload
    }
}
const updateAssets = (payload) => {
    return {
        type: UPDATEASSETS,
        payload
    }
}
const getFiatCoinsList = (payload) => {
    return {
        type: GET_FIATCOINS,
        payload
    }
}
const fetchNetworksData = (networks) => ({
    type: FETCH_NETWORKS,
    payload: networks,
});

const setAssetCoin = (payload) => {
    return {
        type: SET_ASSET,
        payload
    }

}

const setAddress = (payload) => {
    return {
        type: SET_ADDRESS,
        payload
    }

}
const selectedWithdarwData = (payload) => {
    return {
        type: SELECTED_WITHDRAWDATA,
        payload
    }

}
const selectedWalletNetwork = (payload) => {
    return {
        type: SELECTED_WALLET_NETWORK,
        payload
    }

}
const selectedWalletNetworkData = (payload) => {
    return {
        type: SELECTED_WALLET_NETWORKDATA,
        payload
    }

}

export const setselectedNetwork = (error) => ({
    type: SET_SELECTED_NETWORK,
    payload: error,
});
let initialState = {
    depositCryptoList: [{ loading: false, data: [] }],
    depositFiatList: [{ loading: false, data: [] }],
}
const fetchDepositFiatDetails = (customerId, flag) => {

    return async (dispatch) => {
        dispatch(getFiatCoinsList({ loading: true, data: [] }))
        try {
            const response = await getFiatCoins(customerId);
            if (response.ok) {
                dispatch(getFiatCoinsList({ loading: false, data: response.data, isSummary: flag }))
            } else {
                dispatch(getFiatCoinsList({ loading: false, data: [], error: isErrorDispaly(response.originalError?.message) }))
            }
        } catch (error) {
            dispatch(getFiatCoinsList({ loading: false, data: [], error: isErrorDispaly(error) }))
        }
    }
}
const featchDepositCryptoDetails = (customerId, walletType,flag) => {
    return async (dispatch) => {
        dispatch(getCryptoCoinsList({ loading: true, data: [] }))
        try {
            const response = await getCryptoCoins(walletType, customerId)
            if (response.data) {
                dispatch(getCryptoCoinsList({ loading: false, data: response.data, isSummary: flag }))
            } else {
                dispatch(getCryptoCoinsList({ loading: false, data: [], error: isErrorDispaly(response.originalError?.message) }))
            }
        } catch (error) {
            dispatch(getCryptoCoinsList({ loading: false, data: [], error: isErrorDispaly(error) }))
        }

    }
}
export const fetchAssets = (id) => async (dispatch) => {
    try {
        dispatch(updateAssets({ key: "assets", loading: true, data: [] }));
        const response = await getCryptoWallets(id)
        if (response) {
            dispatch(updateAssets({ key: "assets", loading: false, data: response.data }));
        } else {
            dispatch(updateAssets({ key: "assets", loading: false, data: null, error: response.originalError?.message }));
        }
    } catch (error) {
        dispatch(updateAssets(isErrorDispaly(error)));
    }
};
export const fetchNetworks = (walletCode, userId) => async (dispatch) => {
    try {
        const response = await getNetworkLu(walletCode, userId);
        if (response) {
            dispatch(fetchNetworksData({ key: "networks", loading: false, data: response.data }));
        } else {
            dispatch(fetchNetworksData({ key: "networks", loading: false, data: null, error: response.originalError?.message }));
        }
    } catch (error) {
        dispatch(fetchNetworksData(isErrorDispaly(error)));
    }
};
const DepositReducer = (state , action) => {
    state = state || initialState;

    switch (action.type) {
        case GET_CRYPTOCOINS:
            return { ...state, depositCryptoList: action.payload };
        case GET_FIATCOINS:
            return { ...state, depositFiatList: action.payload }
        case UPDATEASSETS:
            return { ...state, assets: action.payload };
        case FETCH_NETWORKS:
            return { ...state, networks: action.payload }
        case SET_ASSET:
            return { ...state, selectedAsset: action.payload };
        case SET_ADDRESS:
            return { ...state, address: action.payload };
        case SELECTED_WITHDRAWDATA:
            return { ...state, selectedWithdarwData: action.payload };
        case SELECTED_WALLET_NETWORK:
            return { ...state, selectedWalletNetwork: action.payload };
        case SELECTED_WALLET_NETWORKDATA:
            return { ...state, selectedWalletNetworkData: action.payload };
        case SET_SELECTED_NETWORK:
            return { ...state, selectedNetwork: action.payload }
        case SET_ADDRESSLOADING:
            return { ...state, setAddressLoading: action.payload }
        default:
            return state;
    }
}
export default DepositReducer;
export {
    getFiatCoinsList, getCryptoCoinsList, featchDepositCryptoDetails, fetchDepositFiatDetails,
    updateAssets, setAssetCoin, setAddress, selectedWithdarwData, selectedWalletNetwork, selectedWalletNetworkData,
}