import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import '../src/assets/css/framework.css';
import '../src/assets/css/styles.css';
import '../src/assets/css/dark-theme.css';
import { Provider } from 'react-redux';
import { store } from './store';
import IdleCmp from './auth/idle.component';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { updateWorker } from './reducers/serviceWorker';
import RootWrapper from './auth/rootWrapper';
const onUpdate = () => {
  store.dispatch(updateWorker());
}
ReactDOM.render(
  <React.StrictMode>
    <RootWrapper>
      <Provider store={store}>
        <IdleCmp />
      </Provider>
    </RootWrapper>
  </React.StrictMode>,
  document.getElementById('root')
);
serviceWorkerRegistration.register({ onUpdate });
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
