import { sellCryptoCoinList } from './api';
const GET_SELLFIATDATA = 'getSellFiatData';
const GET_SELLLISTDATA = 'getSellListData';
const SET_SELLSAVEDATA = "setSellSaveData";
const GET_SELL_SELECTE_DATA="getSellSelectData";

const setSellSaveData = (payload) => {
    return {
        type: SET_SELLSAVEDATA,
        payload
    }
};
const getSellFiatData = (payload) => {
    return {
        type: GET_SELLFIATDATA,
        payload
    };
};
const getSellListData = (payload) => {
    return {
        type: GET_SELLLISTDATA,
        payload
    }
};
const getSellSelectData = (payload) => {
    return { type: GET_SELL_SELECTE_DATA,
        payload
    };
}

const fetchSellDetails = (userId) => {
    return async (dispatch) => {
        dispatch(getSellListData({ key: "sellListData", loading: true, data: null }));
        const response = await sellCryptoCoinList(userId);
        if (response.ok) {
            dispatch(getSellListData({ key: "sellListData", loading: false, data: response?.data }));
        } else {
            dispatch(getSellListData({ key: "sellListData", loading: false, data:[], error: response?.error }));
        }
    }
}

let initialState = {
    sellFiatData: [],
    sellSaveData: null,
    sellListData: {loading: false, data: []},
    sellSelectData:{data: null}
}
const SellReducer = (state ,action) => {
    state = state || initialState

    switch (action.type) {
        case GET_SELLFIATDATA:
            return { ...state, sellFiatData: action.payload };
        case GET_SELLLISTDATA:
            state = { ...state, [action.payload.key]: { ...state[action.payload.key], ...action.payload } };
            return state;
        case SET_SELLSAVEDATA:
            return { ...state, sellSaveData: action.payload };
            case GET_SELL_SELECTE_DATA:
                return { ...state, sellSelectData: action?.payload };
        default:
            return state;
    }
}
export default SellReducer;
export { getSellFiatData, fetchSellDetails, setSellSaveData,getSellSelectData }
