import React, { useState } from "react";
import { Modal, Typography, Button } from 'antd';
import CoinsList from "./coinList";


const { Title } = Typography;

const MarketsModalComponent = ({
    isCoinSelectModalVisible,
    handleOk,
    handleCancel,
    modalTitle,
    onCoinSelected
}) => {
    
    const [cryptoCoins] = useState([]);
    const [selList, setselList] = useState({});

    const handleBuyCoinSelection = (item) => {
        setselList(item);
        if (onCoinSelected) { onCoinSelected(item) }
    }

    return (<>
        <Modal
            centered
            title={
                <>
                    <Title className=" text-primary fw-500 mb-0">{modalTitle}</Title>
                </>
            }
            visible={isCoinSelectModalVisible}
            footer={
                <Button block className="btn-style" onClick={()=>handleOk(selList.walletCode)}><span>confirm {selList.walletCode} </span><span className="icon xxl btn-arrow " ></span></Button>
            }
        >
            <CoinsList
                coinSearch={true}
                coinsRefresh={false}
                coinList={cryptoCoins}
                selectedCoin={selList}
                onCoinSelected={(selectedCoin) => handleBuyCoinSelection(selectedCoin)}
            />
        </Modal>
    </>
    )
}

export default MarketsModalComponent;