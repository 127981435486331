import React, { Component } from 'react'
import { Switch, Route as ReactRoute } from 'react-router-dom';
import DashboardComponent from '../components/dashboard.components';
import SignInSilent from '../auth/signinSilent';
import SecurityLogin from '../auth/securityLogin';
import Auth0 from '../components/auth0.component/auth0'
import EmailVerification from '../components/auth0.component/emailVerification'
import PhoneVerification from '../components/auth0.component/phoneVerification'
import SumSub from '../components/sumSub.component/sumsub';
import AccountStatus from '../utils/accountStatus';
import ProtectedRoute from '../auth/protectedRoute';
import WalletConnectDashboard from '../components/walletConnectDashboard';
const CallbackPage = React.lazy(() => import('../auth/callback.component'));
const Buy = React.lazy(() => import('../components/buy.components/index'));
const SellComponent = React.lazy(() => import('../components/sell.component'));
const Deposit = React.lazy(() => import('../components/deposit.components/index'));
const Payees = React.lazy(() => import('../components/payees.component/index'));
const TransactionGrid = React.lazy(() => import('../components/transactions.component/index'))
const Withdraw = React.lazy(() => import("../components/withdraw.components"))
const TransactionDetails=React.lazy(()=>import('../components/transactions.component/transactiondetails'))
const PlayNowIndex=React.lazy(()=>import('../components/dashboard.components/playNow.component'))
const NotKyc=React.lazy(()=>import('../components/sumSub.component/notKyc'))
// const AddCoins=React.lazy(()=>import('../components/addCoins.component/index'))
const walletType=process.env.REACT_APP_WALLET_TYPE;
class RouteConfig extends Component {

    render() {
        return <Switch>
            <React.Suspense fallback={<div className="loader">Loading...</div>}>
            <ReactRoute path='/welcome' component={WalletConnectDashboard} /> 
                <ReactRoute path='/dashboard'  render={(props)=><ProtectedRoute><DashboardComponent {...props}/></ProtectedRoute>} />
                {/* <ReactRoute path='/coins/add' component={AddCoins} />  */}
                 <ReactRoute path='/playnow' component={PlayNowIndex} /> 
                <ReactRoute path="/callback" component={CallbackPage} />
                <ReactRoute path={'/buy'} render={(props)=><ProtectedRoute><Buy {...props}/></ProtectedRoute>} />
                <ReactRoute path={'/sell'} render={(props)=><ProtectedRoute><SellComponent {...props}/></ProtectedRoute>} />
                <ReactRoute path="/transactions" render={(props)=><ProtectedRoute><TransactionGrid {...props}/></ProtectedRoute>} />
                <ReactRoute path="/transactionDetails/:id/:type" render={(props)=><ProtectedRoute><TransactionDetails {...props}/></ProtectedRoute>}/>
                <ReactRoute path="/deposit/:type?" render={(props)=><ProtectedRoute><Deposit {...props}/></ProtectedRoute>} />
                <ReactRoute path="/withdraw/:type?" render={(props)=><ProtectedRoute><Withdraw {...props}/></ProtectedRoute>} />
                <ReactRoute path="/payees/:type"  render={(props)=><ProtectedRoute><Payees {...props}/></ProtectedRoute>} />
                <ReactRoute path="/silent_redirect" component={SignInSilent} />
                <ReactRoute path="/relogin" component={SecurityLogin} exact />
                <ReactRoute path='/' exact render={(props)=><ProtectedRoute><DashboardComponent {...props}/></ProtectedRoute>} />

                <ReactRoute path="/auth0" component={Auth0} />
                <ReactRoute path="/emailVerification" component={EmailVerification} />
                <ReactRoute path="/phoneVerification" component={PhoneVerification} />
                <ReactRoute path="/sumsub" component={SumSub} />
                <ReactRoute path="/notkyc" component={NotKyc} />
                <ReactRoute path="/accountstatus" component={AccountStatus} />
            </React.Suspense>
        </Switch>
    }
}

export default RouteConfig;