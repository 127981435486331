import apicalls from "../../api/apiCalls";
import { isErrorDispaly } from "../../neo/components/validationsAndExceptions/apiExceptions";
import { getNetworkLu, getWithdrawmemberCrypto } from "./api";
const GET_COINDETAILS = "getCoinDetails"
const GET_FIATREVIEWDETAILS = "getFatReviewDetails"
const GET_CRYPTOREVIEWDETAILS = "getCryptoReviewDetails"
const GET_CRYPTOCOINS = "getCryptoCoinsList"
const GET_FIATCOINS = "getFiatCoinsList"
const GET_FIAT_AMOUNTDATA = "getFiatAmountData"
const GET_CRYPTO_AMOUNTDATA = "getCryptoAmountData"
const GET_FIATLISTDATA = 'getFiatListData';
const GET_CRYPTOLISTDATA = "getCryptoListData";
const GET_FIATADDRESSLISTDATA = "getFiatAddressListData";
const GET_CRYPTOADDRESSLISTDATA = "getCryptoAddressListData";
const SET_SELECTEDCOIN = "setselectedcoin"
const CLEAR_SELECTEDCOIN = "clearselectedcoin"
const FETCH_NETWORKS = 'fetchNetworksData';
const SET_SELECTED_NETWORK = "setselectedNetwork";

const getCoinDetails = (payload) => {
    return {
        type: GET_COINDETAILS,
        payload
    };
}
const getFiatAddressListData = (payload) => {
    return {
        type: GET_FIATADDRESSLISTDATA,
        payload
    };
}
const getCryptoAddressListData = (payload) => {
    return {
        type: GET_CRYPTOADDRESSLISTDATA,
        payload
    };
}
const getFatReviewDetails = (payload) => {
    return {
        type: GET_FIATREVIEWDETAILS,
        payload
    };
}
const getCryptoReviewDetails = (payload) => {
    return {
        type: GET_CRYPTOREVIEWDETAILS,
        payload
    };
}
const getCryptoCoinsList = (payload) => {
    return {
        type: GET_CRYPTOCOINS,
        payload
    }
}

const getFiatCoinsList = (payload) => {
    return {
        type: GET_FIATCOINS,
        payload
    }
}
const getFiatAmountData = (payload) => {
    return {
        type: GET_FIAT_AMOUNTDATA,
        payload
    }
}
const getCryptoAmountData = (payload) => {
    return {
        type: GET_CRYPTO_AMOUNTDATA,
        payload
    }
}
const getFiatListData = (payload) => {
    return {
        type: GET_FIATLISTDATA,
        payload
    }
};
const getCryptoListData = (payload) => {
    return {
        type: GET_CRYPTOLISTDATA,
        payload
    }
};
const setselectedcoin = (payload) => {
    return {
        type: SET_SELECTEDCOIN,
        payload
    }
}
const clearselectedcoin = (payload) => {
    return {
        type: CLEAR_SELECTEDCOIN,
        payload
    }
}
const fetchNetworksData = (networks) => ({
    type: FETCH_NETWORKS,
    payload: networks,
});

const setselectedNetwork = (payload) => ({
    type: SET_SELECTED_NETWORK,
    payload: payload,
});

export const fetchNetworks = (walletCode, userId) => async (dispatch) => {
    try {
        const response = await getNetworkLu(walletCode, userId);
        if (response) {
            dispatch(fetchNetworksData({ key: "networks", loading: false, data: response.data }));
        } else {
            dispatch(fetchNetworksData({ key: "networks", loading: false, data: null, error: response.originalError?.message }));
        }
    } catch (error) {
        dispatch(fetchNetworksData(isErrorDispaly(error)));
    }
};

const featchWithDrawDetails = (customerId, flag) => {
    return async (dispatch) => {
        dispatch(getFiatListData({ key: "fiatListData", loading: true, data: [] }))
        const response = await apicalls.getFiatCoins(customerId);
        if (response.ok) {
            dispatch(getFiatListData({ key: "fiatListData", loading: false, data: response.data, isSummary: flag }))
        } else {
            dispatch(getFiatListData({ key: "fiatListData", loading: false, data: [], error: response.originalError?.message }))
        }
    }
}
const featchWithDrawCryptoDetails = (customerId,walletType, flag) => {
    return async (dispatch) => {
        try{
            dispatch(getCryptoListData({ key: "cryptoListData", loading: true, data: [] }))
            const response = await getWithdrawmemberCrypto(customerId,walletType);
            if (response.ok)
                dispatch(getCryptoListData({ key: "cryptoListData", loading: false, data: response.data, isSummary: flag }))
            else {
                dispatch(getCryptoListData({ key: "cryptoListData", loading: false, data: [], error: response.originalError?.message || response.error }))
            }
        }
        catch (error) {
            dispatch(getCryptoListData({ key: "cryptoListData", loading: false, data: [], error: isErrorDispaly(error) }))
        }

    }
}

let initialState = {
    coinDetailObj: {},
    cryptoCoins: { loading: false, data: []},
    fiatCoins: { loading: false, data: [] },
    fiatReviewDetails: { loading: false, data: {} },
    cryptoReviewDetails: { loading: false, data: {} },
    fiatListData: { loading: false, data: [] },
    cryptoListData: { loading: false, data: [] },
    fiatCoinAmountDetails: null,
    cryptoCoinAmountDetails: null,
    fiatAddressListData: null,
    cryptoAddressListData: null, selectedWDCoin: null, selectedNetwork: null, networks: null
}
const WithdrawFiatReducer = (state , action) => {
    state = state || initialState

    switch (action.type) {
        case GET_COINDETAILS:
            return { ...state, coinDetailObj: action.payload };
        case GET_FIATREVIEWDETAILS:
            return { ...state, fiatReviewDetails: action.payload };
        case GET_CRYPTOREVIEWDETAILS:
            return { ...state, cryptoReviewDetails: action.payload };
        case GET_CRYPTOCOINS:
            return { ...state, cryptoCoins: action.payload };
        case GET_FIATCOINS:
            return { ...state, fiatCoins: action.payload };
        case GET_FIAT_AMOUNTDATA:
            return { ...state, fiatCoinAmountDetails: action.payload };
        case GET_CRYPTO_AMOUNTDATA:
            return { ...state, cryptoCoinAmountDetails: action.payload };
        case GET_FIATLISTDATA:
        case GET_CRYPTOLISTDATA:
            state = { ...state, [action.payload.key]: { ...state[action.payload.key], ...action.payload } };
            return state;
        case GET_FIATADDRESSLISTDATA:
            return { ...state, fiatAddressListData: action.payload };
        case GET_CRYPTOADDRESSLISTDATA:
            return { ...state, cryptoAddressListData: action.payload };
        case SET_SELECTEDCOIN:
            return { ...state, selectedWDCoin: action.payload }
        case CLEAR_SELECTEDCOIN:
            return { ...state, selectedWDCoin: null }
        case SET_SELECTED_NETWORK:
            return { ...state, selectedNetwork: action.payload }
        case FETCH_NETWORKS:
            return { ...state, networks: action.payload }
        default:
            return state;
    }
}
export default WithdrawFiatReducer;
export { featchWithDrawDetails, featchWithDrawCryptoDetails, getCoinDetails, getFatReviewDetails, getFiatCoinsList, getCryptoCoinsList, getFiatAmountData, getCryptoAmountData, getCryptoReviewDetails, getFiatAddressListData, getCryptoAddressListData, setselectedcoin, clearselectedcoin, setselectedNetwork }