import apicalls from "../../api/apiCalls";
import { isErrorDispaly } from "../../neo/components/validationsAndExceptions/apiExceptions";
import { getCryptoCoins } from "./api";
const SET_FIAT_CURRENCIES = "setFiatCurrencies"
const SET_CRYPTO_COINS_LIST = "setCryptoCoins";
const SET_BUY_SAVE_DATA = "buySaveData";
const SET_BUY_SELECTE_DATA = "buySelectData";

const setFiatCurrencies = (payload) => {
  return { type: SET_FIAT_CURRENCIES, payload };
};

const setCryptoCoins = (payload) => {
  return { type: SET_CRYPTO_COINS_LIST, payload };
};
const buySaveData = (payload) => {
  return { type: SET_BUY_SAVE_DATA, payload };
};
const buySelectData = (payload) => {
  return { type: SET_BUY_SELECTE_DATA, payload };
};
const fetchFiatCurrencies = (userId) => {
  return async (dispatch) => {
    try {
      const response = await apicalls.getFiatCoins(userId);
      if (response.ok) {
        dispatch(
          setFiatCurrencies({
            loader: false,
            data: response?.data,
            error: "",
          })
        );
      } else {
        dispatch(
          setFiatCurrencies({
            loader: false,
            data: null,
            error: response.originalError.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setFiatCurrencies({
          loader: false,
          data: null,
          error: error,
        })
      );
    }
  };
}
const fetchCryptoCoins = (userId) => {
  return async (dispatch) => {
    dispatch(
      setCryptoCoins({
        loader: true,
        data: null,
        error: "",
      })
    );
    try {
      const response = await getCryptoCoins(userId);
      if (response.ok) {
        dispatch(
          setCryptoCoins({
            loader: false,
            data: response?.data,
            error: "",
          })
        );
      } else {
        dispatch(
          setCryptoCoins({
            loader: false,
            data: null,
            error: isErrorDispaly(response),
          })
        );
      }
    } catch (error) {
      dispatch(
        setCryptoCoins({
          loader: false,
          data: null,
          error: error,
        })
      );
    }
  };
};

let initialState = {
  buySaveData: { loader: true, data: null },
  fiatCurrencies: { loader: false, data: null, error: "" },
  cryptoCoins: { loader: true, data: null, error: "" },
  buySelectData: { data: null },
};
const BuyReducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case SET_CRYPTO_COINS_LIST:
      state = {
        ...state,
        cryptoCoins: {
          ...state["cryptoCoins"],
          ...action.payload,
        },
      };
      return state;
    case SET_FIAT_CURRENCIES:
      state = {
        ...state,
        fiatCurrencies: {
          ...state["fiatCurrencies"],
          ...action.payload,
        },
      };
      return state;
    case SET_BUY_SAVE_DATA:
      return { ...state, buySaveData: action?.payload };
    case SET_BUY_SELECTE_DATA:
      return { ...state, buySelectData: action?.payload };
    default:
      return state;
  }
};
export default BuyReducer;
export { fetchFiatCurrencies, buySaveData, buySelectData, fetchCryptoCoins, setCryptoCoins };
