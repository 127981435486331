import { create } from "apisauce";
import { store } from "../store";
const apiClient = create({
    baseURL: process.env.REACT_APP_API_END_POINT
});
const web3Client = create({
    baseURL:process.env.REACT_APP_WEB3_API_END_POINT
})
apiClient.axiosInstance.interceptors.request.use((config) => {
     const access_token = store.getState().oidc?.deviceToken;
     config.headers.Authorization = `Bearer ${access_token}`;
    return config;
});
const gridClient = create({
    baseURL :  process.env.REACT_APP_WALLET_TYPE==='non_custodial' ? process.env.REACT_APP_NCW_GRID_API :process.env.REACT_APP_GRID_API
})
gridClient.axiosInstance.interceptors.request.use((config) => {
    const access_token = store.getState().oidc?.deviceToken;
    config.headers.Authorization = `Bearer ${access_token}`;
    return config;
})
const ipRegistry = create({
    baseURL: 'https://api4.ipregistry.co'
})
const coinGekoClient = create({
    baseURL: process.env.REACT_APP_COIN_GECO_API
})
const ipStackClient = create({
    baseURL: process.env.REACT_APP_IPSTACK_API
})
export { apiClient,gridClient,ipRegistry,coinGekoClient,ipStackClient,web3Client }