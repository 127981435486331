import { isErrorDispaly } from "../neo/components/validationsAndExceptions/apiExceptions";
const handleErrorMessage=(error)=>{
  if(typeof error.message!=='string'){
    return error.message
  }
  if(error.message.includes('INSUFFICIENT_FUNDS')){
    return "Insufficient funds!"
  }
  if(error.message.includes('REPLACEMENT_UNDERPRICED')){
    return "Underpriced!"
  }
  if(error.message.includes('TRANSACTION_REPLACED')){
    return "Transaction replaced!"
  }
  if(error.message.includes('UNPREDICTABLE_GAS_LIMIT')){
    return "Unpredictable gas limit!"
  }
  if(error.message.includes('NONCE_EXPIRED')){
    return "Nonce expired!"
  }
  return error?.message || error?.toString()
}
const formatErrorMessage = (error, from) => {
  if (error && typeof error !== "string" && !from) {
    return isErrorDispaly(error);
  }
  if (error && from === "contract") {
    const errorMessage= error?.details ||
      error?.cause?.reason ||
      error?.reason ||
      error?.error?.message ||
      error?.error?.error?.message ||
      error.fault ||
      handleErrorMessage(error) ||
      error;
    if(typeof errorMessage==='string'){
      return errorMessage[0]?.toUpperCase()+errorMessage.slice(1)
    }
    return errorMessage
  }
  return error?.message || error;
};
export default formatErrorMessage;
