import React from 'react'
import CoinsList from '../../shared.component/coinList';
import AppTitle from '../../../shared/appTitle';
const FiatWallet = (props) => {

    return (
        <div className="layout-bg pannel-bg db-faitwallet">
            <div className="p-12 custom-flex" style={{backgroundColor:'transparent'}}>
                <AppTitle level={5} className="card-title">{process.env.REACT_APP_WALLET_TYPE==='non_custodial' ? 'On/Off Ramp Crypto' : 'Fiat wallets'}</AppTitle>
            </div>
            <CoinsList
                coinList={props.coinList}
                isLoading={props.fiatCoinLoader}
                isFiat={props.dashboardShow} coinFields={props.coinFields} selectdCoin={props.selectCoin} errorMessage={props?.errorMessage}
                screenType={"dashboard"} ShowBalance={props?.ShowBalance} handleMenuBarDropdown={false}/>
        </div>
    )
}
export default FiatWallet