import apiCalls from "../api/apiCalls";
import { isErrorDispaly } from "../neo/components/validationsAndExceptions/apiExceptions";
import { setNotificationCount } from '../reducers/dashboardreducer';
const USEER_INFO = "userInfo";
const UPDATE_DOC_REQUEST = "updateDocRequest";
const FETCH_TRACK_AUDITLOGS = "fetchtrackauditlogs";
const CLEAR_USER_INFO = "clearUserInfo";
const UPDATE_TWOFACTOR = "updatetwofactor";
const SET_WEB3_USER = "setWeb3User";
const SET_GETTING_USER_INFO = "setGettingUserInfo"
const SET_ARCANA_CHAINS = 'setArcanaChains';
const LOGIN_ERROR_MESSAGE = 'setLoginError'
const userInfo = (payload) => {
    return {
        type: USEER_INFO,
        payload
    }
};
const fetchtrackauditlogs = (payload) => {
    return {
        type: FETCH_TRACK_AUDITLOGS,
        payload
    }
};
const updateDocRequest = (payload) => {
    return {
        type: UPDATE_DOC_REQUEST,
        payload
    }
};
const updatetwofactor = (payload) => {
    return {
        type: UPDATE_TWOFACTOR,
        payload
    }
};
const clearUserInfo = () => {
    return { type: CLEAR_USER_INFO, payload: null }
}
const setGettingUserInfo = (payload) => {
    return {
        type: SET_GETTING_USER_INFO,
        payload
    }
}
const handleWeb3Login = (payload, successCallback, failureCallback) => {
    return async (dispatch) => {
        apiCalls.handleWeb3User(payload).then((user) => {
            if (user.ok) {
                dispatch(userInfo(user.data));
                successCallback?.();
            } else {
                dispatch(userInfo(null));
                failureCallback?.(user)
            }
        }).catch((error) => {
            dispatch(userInfo(null));
            failureCallback?.(error)
        })
    }
}
const getmemeberInfo = (userid) => {
    return async (dispatch) => {
        let twofa;
        apiCalls.getMember(userid).then((res) => {
            if (res.ok) {
                dispatch(setNotificationCount(res.data?.unReadCount))
                res.data.twofactorVerified = twofa;
                dispatch(userInfo(res.data));
            }
        });
    }
}
const setWeb3User = (payload) => {
    return {
        type: SET_WEB3_USER,
        payload
    }
};
const setArcanaChains = (payload) => {
    return {
        type: SET_ARCANA_CHAINS,
        payload
    }
}
const getArcanaSupportedChains = () => {
    return async (dispatch) => {
        dispatch(
            setArcanaChains({
                loader: true,
                data: null,
                error: "",
            })
        );
        try {
            const response = await apiCalls.getArcanaChains();
            const finalRes=await response.json()
            if (response.ok) {
                dispatch(
                    setArcanaChains({
                        loader: false,
                        data: finalRes?.chains,
                        error: "",
                    })
                );
            } else {
                dispatch(
                    setArcanaChains({
                        loader: false,
                        data: null,
                        error: isErrorDispaly(finalRes),
                    })
                );
            }
        } catch (error) {
            console.log(error)
            dispatch(
                setArcanaChains({
                    loader: false,
                    data: null,
                    error: isErrorDispaly(error),
                })
            );
        }
    };
}
const setLoginError=(payload)=>{ 
    return {
        type: LOGIN_ERROR_MESSAGE,
        payload
    }
}
let initialState = {
    arcanaChains: { loading: false, data: [], error: '' },
    gettingUserInfo: '',
    userProfileInfo: null,
    trackAuditLogData: {},
    web3User: null,
    twoFA: { loading: true, isEnabled: false },
    logginErrorMsg:null,
};
const UserConfig = (state,action ) => {
    state = state || initialState
    switch (action.type) {
        case USEER_INFO:
            state = { ...state, userProfileInfo: action.payload }
            return state;
        case UPDATE_DOC_REQUEST:
            state = { ...state, userProfileInfo: { ...state.userProfileInfo, isDocsRequested: action.payload } }
            return state;
        case UPDATE_TWOFACTOR:
            if (typeof action.payload == "boolean")
                state = { ...state, userProfileInfo: { ...state.userProfileInfo, twofactorVerified: action.payload } }
            else
                state = { ...state, twoFA: { loading: action.payload.loading, isEnabled: action.payload.isEnabled } }
            return state;
        case FETCH_TRACK_AUDITLOGS:
            state = { ...state, trackAuditLogData: action.payload }
            return state;
        case CLEAR_USER_INFO:
            state = { ...state, ...initialState,logginErrorMsg:state.logginErrorMsg };
            return state;
        case SET_WEB3_USER:
            state = { ...state, web3User: action.payload };
            return state;
        case SET_GETTING_USER_INFO:
            state = { ...state, gettingUserInfo: action.payload }
            return state;
        case SET_ARCANA_CHAINS:
            state = { ...state, arcanaChains: action.payload }
        return state;
        case LOGIN_ERROR_MESSAGE:
            state = { ...state, logginErrorMsg: action.payload };
                return state;
        default:
            return state;
    }
}

export default UserConfig;
export { getArcanaSupportedChains, userInfo, getmemeberInfo, updateDocRequest, fetchtrackauditlogs, clearUserInfo, updatetwofactor, handleWeb3Login, setWeb3User, setGettingUserInfo,setLoginError };