import { apiClient } from "../../api/clients"
import { ApiControllers } from "../../api/config"



const saveCustomer = (obj) => {
    return apiClient.post(ApiControllers.registration + 'Accounts', obj);
}
const resendEmail = (custId) => {
    return apiClient.get(ApiControllers.customers + "VerifyEmail"+'/'+custId)
}
const sendOtp = (type,custId) => {
    return apiClient.get(ApiControllers.security + "SendOTP/" + custId+'/'+type)
}
const verifyOtp = (otp,custId) => {
    return apiClient.get(ApiControllers.security + "PhoneVerification/" + custId+'/'+ otp)
}
const referalCode = (code)=>{
    return apiClient.get(ApiControllers.customers + "GetReferralMemberDetails/" + code)
}

export { saveCustomer, resendEmail, sendOtp, verifyOtp, referalCode }