import React from "react";
import webScreen from "../assets/images/web_screen.png"
import { Alert } from "antd";
import { useSelector } from "react-redux";
import { WarningOutlined } from '@ant-design/icons'
import { store } from "../store";
import { setLoginError } from "../reducers/configReduser";
import Web3LoginButton from "../auth/web3LoginButton";
const icon = <WarningOutlined />;

const WalletConnectDashboard = () => {
  const { logginErrorMsg } = useSelector((state) => state.userConfig);
  const handleError = () => {
    store.dispatch(setLoginError(null))
  }
  return (<div className="">

    <div className="banner-shadow">
      <div className="main-container">
        {logginErrorMsg && (
          <div className="alert-flex">
            <Alert
              type="error"
              description={logginErrorMsg}
              icon={icon}
              showIcon
            />
            <span className="icon sm alert-close c-pointer" onClick={() => handleError()}></span>
          </div>
        )}
        <h1 className="wallets-title text-center d-block">Welcome back!</h1>
        <h1 className="wallets-title mb-12 text-center"><span> Please log in to continue trading on our <span className="text-linkblue">web3</span> non-custodial exchange.</span> </h1>


        <div className="wallet-btns dashb-btn mt-24">
          <div className="text-center">
            <Web3LoginButton buttonClassName={"login-filled-btn"} hasIcon={true} IconComponent={<span className="icon lg right-arrow c-pointer"></span>}/>
          </div>
          {/* <AppButton block className="btn-style cancel-btn">Guest mode <span className="icon lg right-arrow c-pointer" /></AppButton> */}
        </div>
        <div className="text-center mt-24">
          <img src={webScreen} className="crypto-img" alt="Dashboard"/>
        </div>
      </div>
    </div>
  </div>
  );
}



export default WalletConnectDashboard;
