import React, { useEffect, useState } from "react";
import { List, Drawer, Typography, Alert, Empty } from "antd";
import { getNotifications } from "./api";
import ConnectStateProps from "../../utils/connectStateProps";
import Moment from "react-moment";
import Loader from "../../shared/loader";
import { store } from "../../store";
import { isErrorDispaly } from '../../neo/components/validationsAndExceptions/apiExceptions';

const { Text } = Typography;
const Notifications = ({
  onClose,
  showDrawer
}) => {
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    fetchNotifications();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps


  const fetchNotifications = async () => {
    const { userConfig: { userProfileInfo } } = store.getState();
    try {
      if (userProfileInfo?.id) {
        setLoading(true);
        let response;
          response = await getNotifications(userProfileInfo.id, "Web3Exchange");
        if (response.ok) {
          setNotifications(response || []);
        } else {
          setError(isErrorDispaly(response));
        }
      }
    } catch (err) {
      setError(isErrorDispaly(err));
    } finally {
      setLoading(false);
    }
  };
  
  const convertUTCToLocalTime = (dateString) => {
    let date = new Date(dateString);
    const milliseconds = Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    );
    return new Date(milliseconds).toISOString()
  };

  return (
      <Drawer
      title={
        <div className="side-drawer-header">
          <Text className="drawer-maintitle">Notifications</Text>
          <span onClick={onClose} className="icon lg close c-pointer" />
        </div>
      }
        placement="right"
        closable={false}
        onClose={onClose}
        visible={showDrawer}
        className="side-drawer"
      >
        {!loading && error && (
          <Alert type="error" description={error} showIcon />
        )}
        {loading && <Loader />}
         {!loading && 
         <List
          itemLayout="vertical"
          size="large"
          className="notifications-list"
          loading={loading}
          locale={{
            emptyText: (
              <Empty
                className="mt-36"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={"No notifications available"}
              />
            )
          }}
        >
          {!loading && (!notifications?.data || notifications?.data?.length === 0) && (<div className="no-data-height">
            <Empty
              className="mt-36"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={"No notifications available"}
            />
         </div> )}
          {notifications?.data?.map((item) => (
            <List.Item
              key={item.id}
              style={{ borderWidth: "0px" }}
            >
              <List.Item.Meta
                className={`${item?.actionBy?.toLowerCase()}bg mb-0`}
                avatar={
                  <span
                   className={`icon md notifyIcon ${item?.message?.toLowerCase()}`}
                  />
                }
                title={
                  <div className="d-flex justify-content align-center">
                    <Text className="profile-value">
                        {item.actionBy}
                        </Text>
                    <Text className="profile-label">
                      <Moment format={"DD MMM YY hh:mm A"}>
                        {item.notifiedDate ? convertUTCToLocalTime(item.notifiedDate) : item.notifiedDate}
                      </Moment>
                    </Text>
                  </div>
                }
                description={
                  <Text
                    className={` value-description fs-12`}
                  >
                    { item.message}{" "}
                  </Text>
                }
              />
            </List.Item>
          ))}
        </List>
         }
      </Drawer>
  );
};

export default ConnectStateProps(Notifications);
