import { ApiControllers } from "../../api/config";
import { apiClient, gridClient,web3Client } from '../../api/clients'

const getPayeesGrid = (id,type,pageNo, pageSize,search) => {
    return gridClient.get(ApiControllers.common + `payees/${id}/${type}/${pageSize}/${pageNo}/${search}`)
}
const getPayeesFiatData = (payeeId) => {
    return apiClient.get(ApiControllers.common + `payee/Fiat/${payeeId}`)
}
const getCountryStateLu = () => {
    return apiClient.get(ApiControllers.common + "States");
}
const savePayeeFiatData = (obj,method) => {
    if(method==='post'){
        return apiClient.post(ApiControllers.common + `Payee/Fiat`, obj);
    }else{
        return apiClient.put(ApiControllers.common + `Payee/Fiat`, obj);
    }
}
const payFiatEdit = (id, type) => {
    return apiClient.get(ApiControllers.addressbook + `payee/Withdraw/Favorite/${id}/${type}`)
}
const getPayeeInfo=(type,id)=>{
    return process.env.REACT_APP_WALLET_TYPE !=='non_custodial' ? apiClient.get(ApiControllers.common + `Payee/${type}/${id}`) : web3Client.get(ApiControllers.common + `Payee/${type}/${id}`);
}
const getPayeeCryptoGrid = (pageNo, pageSize) => {
    return gridClient.get(ApiControllers.address + `AddressCrypto?page=${pageNo}&pageSize=${pageSize}`)
}
const getPayeeCryptoCoinsLU = (id) => {
    return process.env.REACT_APP_WALLET_TYPE !=='non_custodial' ?apiClient.get(ApiControllers.common + `Coins/${id}`) : web3Client.get(ApiControllers.common + `Coins/${id}`);
}
const getNetworks=(coin)=>{
    return process.env.REACT_APP_WALLET_TYPE !=='non_custodial' ?apiClient.get(ApiControllers.common + `Wallets/NetworkLu/${coin}`) : web3Client.get(ApiControllers.common + `Wallets/NetworkLu/${coin}`);
}
const savePayeeCryptoData = (obj,method) => {
    if(method==='post'){
        return process.env.REACT_APP_WALLET_TYPE !=='non_custodial' ?apiClient.post(ApiControllers.common + `Payee/Crypto`, obj) : web3Client.post(ApiControllers.common + `Payee/Crypto`, obj);
    }else{
        return process.env.REACT_APP_WALLET_TYPE !=='non_custodial' ?apiClient.put(ApiControllers.common + `Payee/Crypto`, obj) : web3Client.put(ApiControllers.common + `Payee/Crypto`, obj);
    }
 
};
const getCryptoData = (id) => {
    return process.env.REACT_APP_WALLET_TYPE !=='non_custodial' ?apiClient.get(ApiControllers.common + `Payee/Crypto/${id}`) : web3Client.get(ApiControllers.common + `Payee/Crypto/${id}`);
};
const activeInactive = (obj) => {
    return process.env.REACT_APP_WALLET_TYPE !=='non_custodial' ? apiClient.put(ApiControllers.common + "useractiveinactive", obj) : web3Client.put(ApiControllers.common + "useractiveinactive", obj);
};
const getIBANData = (ibannumber) => {
    return apiClient.get(
        ApiControllers.exchangewallet + `GetIBANAccountDetails?ibanNumber=` + ibannumber
    );
};
const getStatesOfCountry=(country)=>{
    return apiClient.get(ApiControllers.common + `States/${country}`);
}
export { getPayeesGrid, getPayeesFiatData, getCountryStateLu, savePayeeFiatData, payFiatEdit, getPayeeCryptoGrid, getPayeeCryptoCoinsLU, savePayeeCryptoData, getCryptoData, activeInactive, getIBANData,getPayeeInfo,getNetworks,getStatesOfCountry };


