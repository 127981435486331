import apicalls from '../api/apiCalls';
const SET_TRANSACTION_GRID_DATA = 'getTransactionData';

const getTransactionData = (payload) => {
    return {
        type: SET_TRANSACTION_GRID_DATA,
        payload
    }
};

const fetchTransactionDetails = (apiMethod, pageNo, pageSize) => {
    return async (dispatch) => {
        try {
            dispatch(getTransactionData({ key: "transactioListData", loading: true, data: null }));
            const response = await apicalls.getOrderHistoryGrid(apiMethod, pageNo, pageSize);
            if (response.status===200) {
                dispatch(getTransactionData({ key: "transactioListData", loading: false, data: response.data }));
            } else {
                dispatch(getTransactionData({ key: "transactioListData", loading: false, data: null, error: response.originalError.message }));
            }
        } catch (error) {
            dispatch(getTransactionData({ key: "transactioListData", loading: false, data: null, error: error.message || error }));
        }
    }
}

let initialState = {
    transactioListData: { loading: false, data: [], error: null }
}
const TransactionGridReducer = (state , action) => {
    state = state || initialState

    switch (action.type) {
        case SET_TRANSACTION_GRID_DATA:
            state = { ...state, [action.payload.key]: { ...state[action.payload.key], ...action.payload } };
            return state;
        default:
            return state;
    }
}
export default TransactionGridReducer;
export { getTransactionData, fetchTransactionDetails }
