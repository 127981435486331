import React, { useEffect, useState } from 'react';
import {Button, Col, Form, Input,Image, Alert, Spin } from 'antd';
import Mobile from '.././../assets/images/mobile.png';
import { sendOtp, verifyOtp } from './api';
import { getmemeberInfo } from '../../reducers/configReduser';
import { connect } from 'react-redux';

const PhoneVerification = (props) => {
    const [isOtpSent, setSendOTP] = useState(false);
    const [isOtpReSent, setReSendOTP] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [counter, setCounter] = useState(60);
    const [resendLoader, setResendLoader] = useState(false);


    useEffect(() => {
        handleOtp("send")
        { isOtpSent && <strong >{formattedCount}</strong> }
    }, [])
    const inititeCounter = () => {
        let _count = 60;
        const interval = setInterval(() => {
            if (_count === 0) {
                clearInterval(interval);
                setReSendOTP(false);
            } else {
                _count--;
                setCounter(_count);
            }
        }, 1000);

    }
    const formattedCount = `${Math.floor(counter / 60)
        .toString()
        ?.padStart(2, '0')}:${(counter % 60).toString().padStart(2, '0')}`;

    const handleOtp = async (type) => {
        if (type === "resend" || type === "send") {
            setCounter(60);
            setResendLoader(true);
        }
        setError(null);
        const res = await sendOtp(type === "resend" ? "resend" : "send", props?.userProfile.id);
        if (res.ok) {
            if (type === "resend") {
                setResendLoader(false);
                setReSendOTP(true);
                inititeCounter();
            } else {
                setResendLoader(false);
                setReSendOTP(true);
                inititeCounter();
                setSendOTP(true);
            }
        } else {
            setResendLoader(false)
            setError(res.data?.title || res.data?.message || res.data || res.originalError?.message)
        }
        setLoading(false);
    }
    const verifyOTP = async (values) => {
        setLoading(true);
        const res = await verifyOtp(values.otp,props?.userProfile.id);
        if (res.ok) {
            props?.getmemeberInfoa(props?.userProfile.userId)
            if (!props?.userProfile?.isKYC) {
                props.history.push(`/sumsub`);
            } else {
                props.history.push("/accountstatus")
            }
        } else {
            setError(res.data?.title || res.data?.message || res.data || res.originalError?.message)
        }
        setLoading(false);
    }
    return (
        <>
            <div className='main-container'>
                <div className='register-blockwid form-block mobile-verification'>
                    <h2 class="db-main-title mb-8 db-mb-cust">Mobile number verification</h2>
                    <p className='text-style mt-0'>We take the security of our users’ data seriously. To protect our users from fraud and abuse, we require you to please verify your mobile number.</p>
                    {error !== null && <Alert type='error' message={error} closable={false}className='mb-alert cust-auth-alert' showIcon />}
                    <div className=''>

                        <Form  initialValues={{ otp: "" }} onFinish={verifyOTP}>
                            <div className='d-flex '>
                                <div><Image src={Mobile} className='cust-phnimage' preview={false} /></div>
                                <Col xs={24} md={24} lg={22} xl={22} xxl={22} className='custauth-mb auth-phverification'>
                                <Form.Item
                                    className="mb-8 px-4 text-white-50 custom-forminput custom-label pt-8 sc-error auth0-cust-form auth-custphn"
                                    name="otp"
                                    label="Please enter the code that was sent to your mobile number"
                                    rules={[{
                                        required: true,
                                        message: "Please enter otp"
                                    }]}
                                >
                                    <Input
                                        className="cust-input form-disable"
                                        maxLength={6}
                                    />
                                   
                                </Form.Item>
                                </Col>
                            </div>
                            <Button loading={loading} htmlType="submit" size="large" block className="pop-btn cust-color-style">Verify</Button>
                        </Form>
                    </div>

                    <div className='text-center my-24'>
                        <div className='text-style mb-8'>Didn't receive the code? {!isOtpReSent && <span className="text-personal point-cursor c-pointer text-hover" onClick={() => handleOtp("resend")}>Resend
                            {resendLoader && <Spin size='small' />}
                        </span>}{isOtpReSent && <strong >{formattedCount}</strong>}</div>
                    </div>


                    <div className='text-center my-24'>
                        <div className='text-style mb-8'>Having issues with the mobile verification? Please contact us at</div>
                        <div className='text-spacedec width-inc'><a className='text-personal' href="mailto:support@digitalbank.com">support@digitalbank.com</a></div>
                    </div>
                </div>
            </div>
        </>
    );
}
const connectStateToProps = ({ userConfig }) => {
    return { userProfile: userConfig?.userProfileInfo }
  }
  const connectDispatchToProps = dispatch => {
    return { dispatch,
      getmemeberInfoa: (useremail) => {
        dispatch(getmemeberInfo(useremail));
      }, }
  }
 export default connect(connectStateToProps, connectDispatchToProps)(PhoneVerification);
