import { Input } from "antd";
import { Component } from "react";
import NumberFormat from 'react-number-format';

class AppNumber extends Component {
    render() {
        const {thousandSeparator, type, defaultValue, prefixText, suffixText, onChange, prefix = " ", suffix = '',className, bordered = false, inputCustomStyle,disable, autoFocus = false,placeholder,decimalScale,maxLength,onFocus,onValueChange } = this.props;
        return <>
        {type === "input" ? <NumberFormat className={className} customInput={Input} thousandSeparator={thousandSeparator} prefix={prefix}
            placeholder={placeholder || ""}
            bordered={bordered}
            suffix={suffix}
            style={inputCustomStyle}
            value={defaultValue}
            decimalScale={decimalScale}
            maxLength={maxLength}
            disabled={disable}
            onChange={(value) => {
                if (onChange) { onChange(value) }
            }}
            onValueChange={({ value }) => {
                if (onValueChange) { onValueChange(value) }
            }}
            autoFocus={autoFocus}
            allowNegative={false}
            onFocus={onFocus}
        /> : <NumberFormat
            value={defaultValue}
            className={className}    
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={decimalScale}
            renderText={(value, props) => <span {...props}>{prefixText ? prefixText : ""} {value} {suffixText ? suffixText : ""}</span>} 
            />}</>
    
    }
    
}

export default AppNumber;