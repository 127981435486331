import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import authReducer from '../reducers/authReducer';
import UserConfig from '../reducers/configReduser';
import UserprofileReducer from '../reducers/UserprofileReducer';
import SellReducer from '../components/sell.component/reducer';
import WithdrawFiatReducer from '../components/withdraw.components/reducer';
import DepositReducer from '../components/deposit.components/reducer';
import TransactionGridReducer from '../reducers/transactionGridReducer';
import serviceWorkerReducer from '../reducers/serviceWorker';
import BuyReducer from '../components/buy.components/reducer';
import PayeeReducer from '../components/payees.component/reducer';
import dashboardReducer from '../reducers/dashboardreducer';
const persistConfig = {
    key: "root",
    storage,
    whitelist: ["auth"]
}
const rootReducer = combineReducers({
    oidc: authReducer,
    userConfig: UserConfig,
    userProfile: UserprofileReducer,
    sellData : SellReducer,
    WithdrawData : WithdrawFiatReducer,
    serviceWReducer:serviceWorkerReducer,
    buyData: BuyReducer,
    depositData: DepositReducer,
    payeeData:PayeeReducer,
    transactionGrid:TransactionGridReducer,
    dashboardReducer:dashboardReducer,
});
const reducer = persistReducer(persistConfig, rootReducer)
let store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(thunk)),
);
const persistor = persistStore(store);

export { store, persistor }