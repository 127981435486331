import { apiClient, web3Client } from "../../api/clients";
import { ApiControllers } from "../../api/config";
const getCryptoCoins = async (walletType, id) => {
    let client;
    switch (walletType) {
        case 'non_custodial': {
            client = web3Client
            break;
        }
        case 'custodial': {
            client = apiClient
            break
        }
        default: {
            client = apiClient
        }
    }
    return client.get(ApiControllers.dashboard + `Crypto/${id}`);
}

const depositCryptoCoin=(userId)=>{
    return process.env.REACT_APP_WALLET_TYPE!=='non_custodial' ?apiClient.get(ApiControllers.exchangewallet+`Deposit/ExchangeCryptoWallets/${userId}`) : web3Client.get(ApiControllers.exchangewallet+`Deposit/ExchangeCryptoWallets/${userId}`);
}
const depositeCryptoCoinDetails=(walletcode,network,userId)=>{
    return process.env.REACT_APP_WALLET_TYPE!=='non_custodial' ?apiClient.get (ApiControllers.exchangewallet+`DepositCrypto/Exchange/${userId}/${walletcode}/${network}`) : web3Client.get (ApiControllers.exchangewallet+`DepositCrypto/Exchange/${userId}/${walletcode}/${network}`);
}
const getFiatCoins =(id)=>{
    return process.env.REACT_APP_WALLET_TYPE !=='non_custodial' ?apiClient.get(ApiControllers.exchangewallet + `Exchange/FiatWallets/${id}`) : web3Client.get(ApiControllers.exchangewallet + `Exchange/FiatWallets/${id}`);
}

const getFiatCoinsLu =(id,userId)=>{
    return apiClient.get(ApiControllers.exchangewallet+`Deposit/ExchangeFiat/Bank/${id}/${userId}`);
}

const getNetworkLu = (walletcode,userId) => {
    return process.env.REACT_APP_WALLET_TYPE !=='non_custodial' ?apiClient.get(ApiControllers.common + `Wallets/NetWorkLU/${walletcode}/${userId}`) : web3Client.get(ApiControllers.common + `Wallets/NetWorkLU/${walletcode}/${userId}`);
}
const getcustomerBanks=(walletcode)=>{
    return apiClient.get(ApiControllers.exchangewallet+`Deposit/ExchangeFiat/CustomerBanks/${walletcode}`);
}
const getCryptoWallets = (userId) => {
    return apiClient.get(ApiControllers.exchangewallet + `Walltes/CryptoWallets/${userId}`);
}
const getAddWalletsNetworkLU=(userId,walletCode)=>{
    return apiClient.get(ApiControllers.exchangewallet + `Wallets/DepositCrypto/${userId}/${walletCode}`);
}
const addWalletConfirm=(obj)=>{
    return apiClient.post(ApiControllers.exchangewallet + `AddWallet/DepositCrypto`,obj);
}
const getAddWalletsCoinlu=(userId)=>{
    return apiClient.get(ApiControllers.exchangewallet + `Wallets/DepositCrypto/${userId}`);
}

export {depositCryptoCoin,depositeCryptoCoinDetails,getFiatCoins,getFiatCoinsLu,getNetworkLu,getcustomerBanks,getCryptoCoins,
    getCryptoWallets,getAddWalletsNetworkLU,addWalletConfirm,getAddWalletsCoinlu
}