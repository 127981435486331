import React from 'react'
import CoinsList from '../../shared.component/coinList';
import AppTitle from '../../../shared/appTitle';
const CryptoWallet = (props) => {
  return (
    <div className="left-panel pannel-bg mb-leftpanel leftcard crypto-wallet">
    <div className="custom-flex"style={{backgroundColor:'transparent'}}>
        <AppTitle level={5} className="card-title">Crypto wallets</AppTitle>
    </div>
    <CoinsList
        coinList={props.coinList}
        isLoading={props.cryptoCoinLoader}
        isCrypto={props.dashboardShow} coinFields={props.coinFields}
        selectdCoin={props.selectCoin}
        coinsRefresh={props.coinsRefresh}
        errorMessage={props?.errorMessage}
        screenType={"dashboard"} dropdownhide={false} handleMenuBarDropdown={true} />
</div>
  )
}
export default CryptoWallet