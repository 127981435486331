import React, { useEffect, useState } from "react";
import { Col, Tooltip } from 'antd';
import { getTotalFiat, getFiatCoins, getCryptoDashboard, getCryptoTotal } from "./api";
import Breadcrumb from '../../layout/componentsBreadcrumb';
import MarketsModalComponent from '../shared.component/marketsModalComponent';
import OrderGrid from '../../neo/orderGrid'
import FiatWallet from "./wallets.components/fiatWallet";
import CryptoWallet from "./wallets.components/cryptoWallet";
import QuickLinks from "./quickLink.components/quickLinks";
import PortfolioStatus from "./quickLink.components/portfolioStatus";
import TotalBalances from "./fiatCryptoBalances.component"
import { connect } from "react-redux";
import AppRow from "../../shared/appRow";
import AppButton from "../../shared/appButton";
import AppTitle from "../../shared/appTitle"
import AppText from "../../shared/appText";
import MarketsComponent from "./markets.component/markets.component";
import { isErrorDispaly } from "../../neo/components/validationsAndExceptions/apiExceptions";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useAccount } from "wagmi";

// Replace with the token contract address you want to query:
const breadCrumbList = [
    { id: '1', bname: 'Exchange', url: `/dashboard` },
    { id: '2', bname: 'Dashboard', url: `/dashboard` }
];
const DashboardComponent = (props) => {
    const [marketsModal, setMarketsModal] = useState(false);
    const [fiatLoader, setFiatLoader] = useState(false);
    const [cryptoLoader, setCryptoLoader] = useState(false);
    const [isBalanceloader, setIsBalanceloader] = useState(false);
    const [fiatTotalBalances, setFiatTotalBalances] = useState({});
    const [cryptoCoinList, setCryptoCoinList] = useState([]);
    const [faitErrorMessage, setFiatErrorMessage] = useState()
    const [cryptoErrorMessage, setCryptoErrorMessage] = useState()
    const [errorMessage, setErrorMessage] = useState()
    const dashboardShow = true
    const [fiatCoinList, setFiatCoinList] = useState([]);
    const apiMethod = `DashBoard/Customer/DashBoardTransactions/${props?.userProfileInfo?.id}`
    const fieldsCrypto = { currencyCode: 'walletCode', amount: 'avilable', showTradebtn: true, istradeText: true, logo: 'logo' }
    const fields = { currencyCode: 'walletCode', amount: 'avilable', showTradebtn: true, logo: 'logo' }
    const dashboardRef = React.useRef(null)
    const history = useHistory();
    const { isConnected, address } = useAccount()
    useEffect(() => {
        if (process.env.REACT_APP_WALLET_TYPE === 'non_custodial' && isConnected && address) {
            loadDashboardData();
        }
        else if (process.env.REACT_APP_WALLET_TYPE !== 'non_custodial')
            loadDashboardData();
    }, [isConnected, address]);// eslint-disable-next-line react-hooks/exhaustive-deps
    const loadDashboardData = async () => {
        setIsBalanceloader(true);
        setFiatLoader(true);
        setCryptoLoader(true);
        await Promise.all([
            getCryptoDashboard(process.env.REACT_APP_WALLET_TYPE, props?.userProfileInfo?.id).then(response => {
                if (response.ok) {
                    setCryptoCoinList(response.data);
                    setCryptoLoader(false);
                    if (process.env.REACT_APP_WALLET_TYPE === 'non_custodial') {
                        let cryptoTotal = getCryptoTotal(response.data);
                        setFiatTotalBalances(cryptoTotal);
                        setIsBalanceloader(false);
                    }
                }
                else {
                    setCryptoLoader(false)
                    setIsBalanceloader(false);
                    setCryptoErrorMessage(isErrorDispaly(response))
                    dashboardRef.current.scrollIntoView(); window.scrollTo(0, 0)
                }
            }).catch(error => {
                setCryptoLoader(false);
                setIsBalanceloader(false);
                setCryptoErrorMessage(isErrorDispaly(error));
                dashboardRef.current.scrollIntoView(); window.scrollTo(0, 0)
            }),
            getFiatCoins(process.env.REACT_APP_WALLET_TYPE, props?.userProfileInfo?.id).then(response => {
                if (response.ok) {
                    if (process.env.REACT_APP_WALLET_TYPE === 'non_custodial') {
                        let _list = response.data.map(item => ({
                            "id": item.id,
                            "customerId": item.clientId,
                            "walletCode": item.title,
                            "avilable": null,
                            "baseValue": null,
                            "convertedValue": null,
                            "logo": item.icon,
                            "recorder": null
                        }));
                        setFiatCoinList(_list);
                    }
                    else {
                        setFiatCoinList(response.data);
                    }
                    setFiatLoader(false);
                }
                else {
                    setFiatLoader(false)
                    setFiatErrorMessage(isErrorDispaly(response))
                    dashboardRef.current.scrollIntoView(); window.scrollTo(0, 0)
                }
            }).catch(error => {
                setFiatLoader(false);
                setCryptoErrorMessage(isErrorDispaly(error));
                dashboardRef.current.scrollIntoView(); window.scrollTo(0, 0)
            }), getTotalFiat(process.env.REACT_APP_WALLET_TYPE, props?.userProfileInfo?.id)?.then(response => {
                if (process.env.REACT_APP_WALLET_TYPE !== 'non_custodial') {
                    if (response.ok) {
                        setFiatTotalBalances((response.data))
                        setIsBalanceloader(false);
                    }
                    else {
                        setIsBalanceloader(false)
                        setErrorMessage(isErrorDispaly(response))
                        dashboardRef.current.scrollIntoView(); window.scrollTo(0, 0)
                    }
                }
            }).catch(error => {
                setIsBalanceloader(false);
                setCryptoErrorMessage(isErrorDispaly(error));
                dashboardRef.current.scrollIntoView(); window.scrollTo(0, 0)
            })])
    }
    const getSelectedCoin = (item) => {
        props?.selectdCoin(item);
    }
    const handleCoinSelected = () => {
        setMarketsModal(false);
    }
    const handlePlayNow = () => {
        history.push(`/playnow`);
    }
    return (<>
        <div ref={dashboardRef}></div>
        <div className="dashboard-content">
            <Breadcrumb breadCrumbList={breadCrumbList} />
            <Col sm={24} md={24} lg={24} xl={24} xxl={24} className="mb-order-2">
            </Col>
            <AppRow gutter={[16, 16]} className='db-mobile'>
                {((process.env.REACT_APP_WALLET_TYPE === 'non_custodial' && address) || (process.env.REACT_APP_WALLET_TYPE !== 'non_custodial')) && <> <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={18} className="mb-order-1">
                    <AppRow gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10} >
                            <FiatWallet fiatCoinLoader={fiatLoader} coinList={fiatCoinList} dashboardShow={dashboardShow} ShowBalance={process.env.REACT_APP_WALLET_TYPE === 'non_custodial' ? true : false}
                                coinFields={fields} selectCoin={(item) => getSelectedCoin(item)} errorMessage={faitErrorMessage} />
                            <CryptoWallet cryptoCoinLoader={cryptoLoader} coinList={cryptoCoinList} dashboardShow={dashboardShow}
                                coinFields={fieldsCrypto} selectCoin={(item) => getSelectedCoin(item)} coinsRefresh={false} errorMessage={cryptoErrorMessage} />
                        </Col>
                        <Col sm={24} md={24} lg={14} xl={14} xxl={14} className="mb-order-2">
                            <div className="db-quicklinks">
                                <div className="custom-flex ql-mb">
                                    <AppTitle level={5} className="card-title">Quick links</AppTitle>
                                </div>
                                <div className=''>
                                    <QuickLinks />
                                </div>
                            </div>
                            <div>
                                <PortfolioStatus />
                            </div>
                        </Col>
                    </AppRow>
                    <div className="pannel-bg layout-bg dashboard-transactions sm-m-0">
                        <OrderGrid apiMethod={apiMethod} srceenType="dashboard" />
                    </div>
                </Col></>}
                {((process.env.REACT_APP_WALLET_TYPE === 'non_custodial' && address) || (process.env.REACT_APP_WALLET_TYPE !== 'non_custodial')) &&
                    <Col sm={24} md={24} lg={6} xl={6} xxl={6} className="mb-order-3">
                        <TotalBalances isBalanceloader={isBalanceloader} fiatTotalBalances={fiatTotalBalances} errorMessage={errorMessage} />
                        <div className="pannel-bg layout-bg left-panel p-16 howit-works">
                            <div >
                                <AppTitle level={5} className="card-title db-hw">How it <br /> <span>works?</span></AppTitle>
                            </div>
                            <div>
                                <Tooltip placement="top" title={"Digital Bank is a cutting-edge digital institution merging neobanking with cryptocurrency, offering seamless management of traditional and digital currencies. Operating entirely online, it provides low fees, real-time transaction alerts, automated savings, and secure crypto trading through intuitive mobile and web platforms. For more information, click on 'How to'."}>
                                    <AppText className="db-hw-text">Digital Bank is a cutting-edge digital institution merging neobanking with cryptocurrency, offering seamless management of traditional and digital currencies. Operating entirely online, it provides low fees, real-time transaction alerts, automated savings, and secure crypto trading through intuitive mobile and web platforms. For more information, click on "How to".</AppText>
                                </Tooltip>
                            </div>
                            <div className="dashb-btn" onClick={handlePlayNow}><AppButton block className="btn-style">HOW  TO <span className="icon lg right-arrow c-pointer" /></AppButton>  </div>
                        </div>
                        <div className="pannel-bg layout-bg dashboard-transactions markets">
                            <MarketsComponent />
                        </div>
                    </Col>}

                {!((process.env.REACT_APP_WALLET_TYPE === 'non_custodial' && address) || (process.env.REACT_APP_WALLET_TYPE !== 'non_custodial')) && <Col sm={24} md={24} lg={24} xl={24} xxl={24} className="">
                    <AppRow gutter={[16, 16]} className='db-mobile'>
                        <Col sm={24} md={24} lg={8} xl={8} xxl={8} className="mb-order-3">
                            <div className="pannel-bg layout-bg left-panel p-16 howit-works">
                                <div >
                                    <AppTitle level={5} className="card-title db-hw">How it <br /> <span>works?</span></AppTitle>
                                </div>
                                <div>

                                    <AppText className="db-hw-text-full">Digital Bank is a cutting-edge digital institution merging neobanking with cryptocurrency, offering seamless management of traditional and digital currencies. Operating entirely online, it provides low fees, real-time transaction alerts, automated savings, and secure crypto trading through intuitive mobile and web platforms. For more information, click on "How to".</AppText>

                                </div>
                                <div className="dashb-btn" onClick={handlePlayNow}><AppButton block className="btn-style">HOW  TO <span className="icon lg right-arrow c-pointer" /></AppButton>  </div>
                            </div>
                        </Col>
                        <Col sm={24} md={24} lg={16} xl={16} xxl={16} className="mb-order-3">
                            <div className="pannel-bg layout-bg dashboard-transactions markets-input-search">
                                <MarketsComponent />
                            </div>
                        </Col>
                    </AppRow>
                </Col>}

            </AppRow>
            <MarketsModalComponent
                isCoinSelectModalVisible={marketsModal}
                modalTitle="Markets"
                handleOk={handleCoinSelected}
            />
        </div>
    </>
    )
}
const mapStateToProps = ({ userConfig, dashboard }) => {
    return { userProfileInfo: userConfig.userProfileInfo, dashboard, web3User: userConfig.web3User }
}
export default connect(mapStateToProps, (dispatch) => { return { dispatch } })(DashboardComponent);
