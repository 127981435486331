import React from 'react'
import Loader from '../../../shared/loader';
import { Alert, Divider } from 'antd';
import AppTitle from '../../../shared/appTitle';
import AppNumber from '../../../shared/inputs/appNumber';
const TotalBalances = (props) => {
    return (
        <div className="pannel-bg layout-bg left-panel right-bgpannel p-16">
            {(!props.isBalanceloader&&props.errorMessage !== undefined && props.errorMessage !==null && props.errorMessage !=="") &&
            <Alert type='error' message={props?.errorMessage} closable={false}className='mb-alert cust-auth-alert' showIcon />}
            {(!props.isBalanceloader&&!props.errorMessage) && (process.env.REACT_APP_WALLET_TYPE !=='non_custodial') && <>
                <div className="db-fait fait-title">
                    <div className="card-title ">Fiat</div>
                </div>
                <div className="db-faitcard">
                    <AppTitle level={3}><span className="db-fat-dollar">$</span>
                        <span className="db-faitcard-bal">
                            <AppNumber defaultValue={`${Math.abs(props.fiatTotalBalances.fiatBalance) > 999999 ? Math.sign(props.fiatTotalBalances.fiatBalance) * ((Math.abs(props.fiatTotalBalances.fiatBalance) / 1000000).toFixed(1)) : Math.sign(props.fiatTotalBalances.fiatBalance) * Math.abs(props.fiatTotalBalances.fiatBalance)} `} suffixText={Math.abs(props.fiatTotalBalances.fiatBalance) > 999999 ? "M" : null} thousandSeparator={true} decimalScale={2} displayType={"text"} />
                        </span></AppTitle>
                </div>
            {/* </> */}
            {/* } */}
           {!props.isBalanceloader&& <Divider />} </>}
            {props.isBalanceloader && <div
                className="db-kpi p-relative text-center m-24"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                {" "}
                <Loader />
            </div>}
            {(!props.isBalanceloader&&!props.errorMessage) &&
                <>
                    <div className="db-fait fait-title">
                        <div className="card-title ">Crypto</div>
                    </div>
                    <div className="db-faitcard"><AppTitle level={3}>
                        <span className="db-fat-dollar">$</span>
                        <span className="db-faitcard-bal">
                        <AppNumber
                            defaultValue={
                                props.fiatTotalBalances.cryptoBalance === 0
                                    ? "0.00"
                                    : `${Math.abs(props.fiatTotalBalances.cryptoBalance) > 999999
                                        ? Math.sign(props.fiatTotalBalances.cryptoBalance) * ((Math.abs(props.fiatTotalBalances.cryptoBalance) / 1000000).toFixed(1))
                                        : Math.sign(props.fiatTotalBalances.cryptoBalance) * Math.abs(props.fiatTotalBalances.cryptoBalance)}`
                            }
                            suffixText={
                                props.fiatTotalBalances.cryptoBalance === 0
                                    ? null
                                    : Math.abs(props.fiatTotalBalances.cryptoBalance) > 999999
                                        ? "M"
                                        : null
                            }
                            thousandSeparator={true}
                            decimalScale={2}
                        />
                        </span></AppTitle>
                    </div>
                </>}
        </div>
    )
}
export default TotalBalances