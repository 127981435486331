import { Breadcrumb } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
const ComponentsBreadcrumb  = ({ breadCrumbList }) => {
  const history = useHistory();
  const handleClick = (url) => {
    if (url ) {
      history.push(url); 
    }
  };
  return (
  <Breadcrumb separator={<span className='icon sm bc-arrow' />} className='cust-breadcrumb font-size: 18px !important;'>
    {breadCrumbList?.map(name =>
       <Breadcrumb.Item className="text-white" key={name.id}  onClick={() => handleClick(name.url)}>
        {name.bname}
        </Breadcrumb.Item>
      )}
  </Breadcrumb>
);
}
export default ComponentsBreadcrumb ;