import React, { useEffect, useRef, useState } from 'react';
import { Button, Row, Col, Form, Select, Input, Radio, Alert, Checkbox } from 'antd';
import { referalCode, saveCustomer } from './api';
import Countries from './countries.json';
import apicalls from '../../api/apiCalls';
import { getmemeberInfo } from '../../reducers/configReduser';
import { connect } from 'react-redux';
import Loader from '../../shared/loader'


const Auth0 = (props) => {
  const busssinessForm = useRef();
  const personalForm = useRef();
  const [form] = Form.useForm();
  const [value, setValue] = useState("bussiness");
  const [isBusinessAccount, setIsBusinessAccount] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [saveError, setSaveError] = useState(null);
  const [filteredCountries, setFilteredCountries] = useState([...Countries]);
  const [filteredCodeCountries, setFilteredCodeCountries] = useState([...Countries]);
  const [phoneCode, setPhoneCode] = useState("");
  const [isChecked, setIsChecked] = useState(false)
  const [businessIsChecked, setBusinessIsChecked] = useState(false)
  const [referalError, setReferalError] = useState(null);
  const [referralVerified, setReferralVerified] = useState(false)
  const [referralRes, setReferralRes] = useState(null)
  const [loader,setLoader]=useState()
  const [isUpdate,setIsUpdate] = useState(false);

  useEffect(() => {
    phonecodeOptions()
  }, [])

  const checkBoxChecked = (e) => {
    setIsChecked(e.target.checked)
    setError(null)
  }

  const phonecodeOptions = () => {
    let optionsphone = [];
    let optionscountry = [];
    for (let i in Countries) {
      optionsphone.push({ label: `${Countries[i].name}(${Countries[i].dial_code})`, value: Countries[i].dial_code });
      optionscountry.push({ label: Countries[i].name, value: Countries[i].name });
    }
    setFilteredCountries([...optionscountry]);
    setFilteredCodeCountries([...optionsphone]);
    getIpStockLocation()
  }
 


  const handlePhoneCode = (phonevalue) => {
    setPhoneCode(phonevalue);
  }


  const onChange = (e) => {
    setError(null)
    setReferalError(null)
    setBusinessIsChecked(false)
    setIsChecked(false)
    setSaveError(null)
    setReferralVerified(false)
    form.resetFields()
    getIpStockLocation()
    setValue(e.target.value);setPhoneCode('')
    setIsBusinessAccount("bussiness" === e.target.value);
  };

  const handleSubmmit = async (values) => {
    if (values.referralCode && !referralVerified) {
      if (businessIsChecked === true || isChecked === true) {
        setIsUpdate(true);
      } else {
        setError("Please click the checkbox above after reading and agreeing to the Terms of Service before proceeding")
      }
      return Promise.resolve(true);

    } else if (businessIsChecked === true || isChecked === true) {
      setIsUpdate(true);    
    } else {
      setError("Please click the checkbox above after reading and agreeing to the Terms of Service before proceeding")
    }

    if (isUpdate) {
      if(!phoneCode){
        setSaveError("Please enter the phone code")
      }else{
        setLoading(true);
        setSaveError(null);
        setError(null)
        let obj = {
          "firstName":props?.userProfile?.firstName,"lastName":props?.userProfile?.lastName,"userName":props?.userProfile?.userName,"userId":props?.userProfile?.userId,"PhoneCode":null,"country":null,"isbusines":isBusinessAccount?true:false,"businessName":""
        }
        obj = { ...obj, ...values };
        obj.PhoneCode=phoneCode;
        const response = await saveCustomer(obj);
        if (response.ok) {
          setLoading(false);
        props?.getmemeberInfoa(props?.userProfile.userId)
          if (!props.userProfile?.isPhoneNumberVerified) {
            props.history.push('/phoneVerification');
          } else {
            props.history.push(`/sumsub/${obj.isBusiness ? "2024_SuisseBase_KYB_Flow" : "sumsub-signin-demo-level"}`);

          }
        } else {
          setLoading(false);
          setSaveError(apicalls.isErrorDispaly(response));
        }
      }
     
    }

  }

  const handleReferralValidation = async (_, referralCode) => {
    if (!referralCode) {
      setReferralVerified(false)
      return Promise.resolve(true);
    }
    if (referralCode?.length > 2) {
      const res = await referalCode(referralCode);
      if (!res.ok) {
        setReferalError(null)
        setReferralVerified(false)
        return Promise.reject("Invalid referral code");         
      } else {
        setReferralVerified(true)
        console.log(res.data.name);
        setReferralRes(res.data.name)
        return Promise.resolve(true);
      }
    } else {
      return Promise.reject("Invalid referral code")
    }
  }

  const getIpStockLocation = async () => {
    setLoader(true)
    let res = await apicalls.getIpStock()
    if (res.ok) {
      setLoader(false)
      form.setFieldsValue({ country: res.data.country_name })
      setPhoneCode('+' + res.data.location.calling_code)
    }else{
    setLoader(false)
    }
  }
  return (
    <>
      <div className='register-blockwid form-block cuat-auth-width'>
        <div>
          <h2 class="heading mob-center">Choose Account</h2>
          <Radio.Group onChange={onChange} value={value} className="new-custom-radiobtn mb-24 newcustome-radiocheck">
            <Radio value="bussiness" className="">Business Account</Radio>
            <Radio value="personal" className="">Personal Account</Radio>
          </Radio.Group>
        </div>
        {saveError !== null && (
          <Alert
            type="error"
            description={saveError}
            onClose={() => setSaveError(null)}
            showIcon
          />
        )}
        {isBusinessAccount && <div className='busimness-mt'>
          <h2 class="heading mob-center">Sign up for Business Account</h2>
        {loader&& <div className=""><Loader /></div>}
        {!loader&&<>
          <Form name='busssinessForm'
            ref={busssinessForm}
            onFinish={handleSubmmit}
            form={form}
          >
            <Row className='formfields-block auth0-cust-row' gutter={24}>

              <Col xs={24} md={24} lg={12} xl={12} xxl={12} className='custauth-mb'>
                <Form.Item
                  className=" mb-8 px-4 text-white-50 custom-forminput custom-label pt-8 sc-error auth0-cust-form"
                  name="businessName"
                  label="Legal Business Name"
                  required
                  rules={[
                    {
                      validator(_, validatorvalue) {
                        if (!validatorvalue) {
                          return Promise.reject('Please enter business name');
                        } else if (validatorvalue.length < 2 || validatorvalue.length > 80) {
                          return Promise.reject('Invalid business name')
                        } else if (!(/^[A-Za-z ]*$/.test(validatorvalue))) {
                          return Promise.reject('Invalid business name')
                        } else {
                          return Promise.resolve();
                        }
                      }
                    },
                  ]}>
                  <Input
                    className="cust-input form-disable"
                    maxLength={80}
                    placeholder="Legal Business Name"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={12} xl={12} xxl={12} className='custauth-mb'>
                <Form.Item
                  className=" mb-8 px-4 text-white-50 custom-forminput custom-label pt-8 sc-error form-arrowicon auth0-cust-form"
                  name="phoneNo"
                  label="Phone"
                  required
                  rules={[
                    { required: true, message: "Is required", },
                    {
                      validator(_, phonevalidatorvalue) {
                        if (Number(phonevalidatorvalue) || !phonevalidatorvalue) {
                          return Promise.resolve();
                        } else if (phonevalidatorvalue && !(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(phonevalidatorvalue))) {
                          return Promise.reject("Invalid phone number");
                        } else if (!Number(phonevalidatorvalue)) {
                          return Promise.reject("Only numbers allowed");
                        }
                      },
                    },
                  ]}>
                  <Input
                    addonBefore={<Select
                      style={{ width: '150px' }}
                      className="Approved field-width"
                      showSearch
                      placeholder="Phone"
                      optionFilterProp="children"
                      onChange={handlePhoneCode}
                      value={phoneCode}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={filteredCodeCountries}


                    />}
                    className="cust-input form-disable phone-he c-pointer cust-phone"
                    maxLength={12}
                  placeholder='Phone'/>
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={12} xl={12} xxl={12} className='custauth-mb'>
                <Form.Item className=" mb-8 px-4 text-white-50 custom-forminput custom-label pt-8 sc-error form-arrowicon auth0-cust-form"
                  name="country" label="Country Of Business"
                  rules={[
                    {
                      required: true,
                      message: "Is required",
                    },
                  ]}>
                  <Select
                    className="cust-input Approved custselct-drpdwn"
                    maxLength={15}
                    placeholder="Select Country"
                    optionFilterProp="children"
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={filteredCountries}
                  >

                  </Select>

                </Form.Item>
              </Col>
              
              <Col xs={24} md={24} lg={12} xl={12} xxl={12} className='custauth-mb'>
                <Form.Item
                  className=" mb-8 px-4 text-white-50 custom-forminput custom-label pt-8 sc-error auth0-cust-form"
                  name="referralCode"
                  label="Referral Code"
                  rules={[
                    { validator: handleReferralValidation }
                  ]}
                >
                  <Input
                    className="cust-input form-disable"
                    maxLength={15}
                    placeholder="Referral Code"
                  />

                </Form.Item>
                {referralVerified === true ? (<span className='reffername'>{referralRes}</span>) : ("")}
                <span style={{ color: "red" }}>{referalError}</span>
                {referralVerified === true ? (<span className='icon lg greencheck'></span>) : ("")}
              </Col>
              <Col xs={24} md={24} lg={24} xl={24} xxl={24} className='px-0'>
                <div className='policy-content gap-0 terms-text'>
                  
                   <Form.Item name="isCheck" className={`${error != null ? 'check-error':''}`}>
                    <Checkbox onChange={checkBoxChecked} > </Checkbox>
                    <div className='terms-text'>By clicking submit, I here by acknowledge that i agree to Digital Bank's Terms of use agreement and I've read the Privacy policy.</div>
                   
                  </Form.Item>
                  
                </div>
                {error != null && <Alert className="pa-alert" type='error' closable={false} message={error}/>}
              </Col>
            </Row>
            <Col xs={24} md={24} lg={6} xl={6} xxl={6} className='cust-btn-right'>
            <div className="view-level-btn text-right cust-auth-btn">
              <Form.Item>
                <Button
                  loading={loading}
                  type='primary'
                  className='pop-btn cust-color-style'
                  htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </div>
            </Col>
          </Form></>}
        </div>}

        {!isBusinessAccount && <div className='busimness-mt'>
          <h2 class="heading mob-center">Sign up for  Personal  Account</h2>
          {loader&& <div className=""><Loader /></div>}
          {!loader&&<><Form name='persionalAccount' ref={personalForm} onFinish={handleSubmmit} form={form}>
            <Row className='formfields-block' gutter={24}>
              <Col xs={24} md={24} lg={12} xl={12} xxl={12} className='custauth-mb'>
                <Form.Item
                  className=" mb-8 px-4 text-white-50 custom-forminput custom-label pt-8 sc-error auth0-cust-form"
                  name="firstName"
                  label="First Name"
                  required
                  rules={[
                    {
                      validator(_, fnamevalue) {
                        if (!fnamevalue) {
                          return Promise.reject('Please enter first name');
                        } else if (fnamevalue.length < 2 || fnamevalue.length > 80) {
                          return Promise.reject('Invalid first name')
                        } else if (!(/^[A-Za-z ]*$/.test(fnamevalue))) {
                          return Promise.reject('Invalid first name')
                        } else {
                          return Promise.resolve();
                        }
                      }
                    },
                  ]}>
                  <Input
                    className="cust-input"
                    maxLength={80}
                    placeholder="First Name"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={12} xl={12} xxl={12} className='custauth-mb'>
                <Form.Item
                  className=" mb-8 px-4 text-white-50 custom-forminput custom-label pt-8 sc-error auth0-cust-form"
                  name="lastName"
                  label="Last Name"
                  required
                  rules={[
                    {
                      validator(_, lnamevalue) {
                        if (!lnamevalue) {
                          return Promise.reject('Please enter last name');
                        } else if (lnamevalue.length < 2 || lnamevalue.length > 80) {
                          return Promise.reject('Invalid last name')
                        } else if (!(/^[A-Za-z ]*$/.test(lnamevalue))) {
                          return Promise.reject('Invalid last name')
                        } else {
                          return Promise.resolve();
                        }
                      }
                    },
                  ]}>
                  <Input
                    className="cust-input"
                    maxLength={80}
                    placeholder="Last Name"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={12} xl={12} xxl={12} className='custauth-mb'>
                <Form.Item
                  className=" mb-8 px-4 text-white-50 custom-forminput custom-label pt-8 sc-error auth0-cust-form"
                  name="phoneNo"
                  label="Phone"
                  required
                  rules={[
                    { required: true, message: "Is required", },
                    {
                      validator(_, pnumbervalue) {
                        if (Number(pnumbervalue) || !pnumbervalue) {
                          return Promise.resolve();
                        } else if (pnumbervalue && !(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(pnumbervalue))) {
                          return Promise.reject("Invalid phone number");
                        } else if (!Number(pnumbervalue)) {
                          return Promise.reject("Only numbers allowed");
                        }
                      },
                    },
                  ]}>
                  <Input
                    addonBefore={<Select
                      style={{ width: '150px' }}
                      className="Approved field-width"
                      showSearch
                      placeholder="Phone"
                      optionFilterProp="children"
                      onChange={handlePhoneCode}
                      value={phoneCode}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={filteredCodeCountries}


                    />}
                    className="cust-input phone-he cust-phone"
                    maxLength={12}
                    placeholder="Phone"/>
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={12} xl={12} xxl={12} className='custauth-mb'>
                <Form.Item className="mb-8 px-4 text-white-50 custom-forminput custom-label pt-8 sc-error auth0-cust-form"
                  name="country" label="Country Of Residence"
                  rules={[
                    {
                      required: true,
                      message: "Is required",
                    },
                  ]}>
                  <Select
                    className="cust-input Approved custselct-drpdwn"
                    maxLength={100}
                    placeholder="Select Country"
                    optionFilterProp="children"
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={filteredCountries}
                  >

                  </Select>
                </Form.Item>
              </Col>
             
              <Col xs={24} md={24} lg={12} xl={12} xxl={12} className='custauth-mb'>
                <Form.Item
                  className=" mb-8 px-4 text-white-50 custom-forminput custom-label pt-8 sc-error auth0-cust-form"
                  name="referralCode"
                  label="Referral Code"
                  rules={[
                    { validator: handleReferralValidation }

                  ]}
                >
                  <Input
                    className="cust-input "
                    maxLength={15}
                    placeholder="Referral Code"
                  />
                </Form.Item>
                {referralVerified === true ? (<span className='reffername'>{referralRes}</span>) : ("")}
                <span style={{ color: "red" }}>{referalError}</span>
                {referralVerified === true ? (<span className='icon lg greencheck'></span>) : ("")}
                
              </Col>
              <Col xs={24} md={24} lg={24} xl={24} xxl={24} className='px-0 custauth-mb'>
                <div className='policy-content terms-text d-flex'>
                  
                  <div className="">

                  <Form.Item name="isCheck" className={`${error != null ? 'check-error':''}`}>
                    <Checkbox onChange={checkBoxChecked}></Checkbox>
                      <span className="checkbox-text"><div className='terms-text'>By clicking submit, I here by acknowledge that i agree to Digital Bank's <a href="#" className="blue-color">Terms of use agreement</a> and I've read the <a  href="#" className="blue-color">Privacy policy</a>.</div></span>
                    
                  </Form.Item>
                </div>
                </div>
                {error != null && <Alert className="pa-alert" type='error' closable={false} message={error} />}
              </Col>
            </Row>
            
            <Col xs={24} md={24} lg={6} xl={6} xxl={6} className='cust-btn-right'>
            <div className="view-level-btn text-right cust-auth-btn">
              <Form.Item>
                <Button
                  loading={loading}
                  type='primary'
                  className='pop-btn cust-color-style'
                  htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </div>
            </Col>
          </Form></>}
        </div>}
      </div>
    </>
  );
};
const connectStateToProps = ({ userConfig }) => {
  return { userProfile: userConfig?.userProfileInfo }
}
const connectDispatchToProps = dispatch => {
  return { dispatch,
    getmemeberInfoa: (useremail) => {
      dispatch(getmemeberInfo(useremail));
    }, }
}
export default connect(connectStateToProps, connectDispatchToProps)(Auth0);