import Layout from "./layout";
import { BrowserRouter as Router } from 'react-router-dom'
import { store } from "./store";
import { useEffect, useState } from "react";
import SecurityLogin from "./auth/securityLogin";
import { useAuth0 } from "@auth0/auth0-react";
import Notifications from "./components/notifications.component";
import { startConnection } from "./utils/signalr";
import apicalls from "./api/apiCalls";
import {updatetwofactor} from './reducers/configReduser';

function App() {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const [showNotifications, setShowNotifications] = useState(false);

  const connectToHub = () => {
    setTimeout(() => {
      const { userConfig: { userProfileInfo } } = store.getState();
      if (userProfileInfo?.id) {
        if(process.env.REACT_APP_WALLET_TYPE !== "non_custodial"){
          apicalls.twofactor(userProfileInfo?.id).then(res => {
            if (res.ok) {
              store.dispatch(updatetwofactor({ loading: false, isEnabled: res.data }));
            }
          })
        }        
        startConnection(userProfileInfo?.id);
      } else {
        connectToHub();
      }
    }, 2000)

  }
  
  useEffect(() => {
    
      localStorage.setItem("__url", window.location?.pathname.includes('callback')?'/dashboard':window.location?.pathname);
    
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({redirect_uri: process.env.REACT_APP_REDIRECT_URI});
    } 
   

    connectToHub(); 

  }, [])// eslint-disable-line react-hooks/exhaustive-deps
  return (
      <Router basename={process.env.PUBLIC_URL}>
          <SecurityLogin>
             {isLoading&&process.env.REACT_APP_WALLET_TYPE==="custodial" ? <div className="loader">Loading....</div> : <Layout />}
            <Notifications showDrawer={showNotifications} onClose={() => setShowNotifications(false)} />
          </SecurityLogin>
      </Router>
  );
}

export default App;