import React, { useEffect, useState } from 'react'
import AppTitle from '../../../shared/appTitle';
import LineChart from '../../charts.components/lineChart'
import { dashBoardDaysLu, dashboardGraph } from '../api'
import Loader from '../../../shared/loader';
import { store } from '../../../store';
import AppButton from '../../../shared/appButton';
import { isErrorDispaly } from "../../../neo/components/validationsAndExceptions/apiExceptions";
import { Alert } from 'antd';
const walletType = process.env.REACT_APP_WALLET_TYPE;
const PortfolioStatus = () => {
    const [graphData, setGraphData] = useState({ labels: [], values1: [], values2: [] })
    const [loading, setLoading] = useState(false)
    const [daysLu, setDaysLu] = useState({ data: { Days: [] } })
    const [selectedDay, setSelectedDay] = useState("7Days")
    const [errorMessage, setErrorMessage] = useState(null)
    const { userConfig: { userProfileInfo } } = store.getState();
    useEffect(() => {
        getDayLu()
        getGraphData(userProfileInfo?.id, selectedDay)
    }, [userProfileInfo?.id, selectedDay])
    const getDayLu = async () => {
        setLoading(true);
        setErrorMessage(null);
        try {
            const response = await dashBoardDaysLu(walletType);
            if (response) {
                setDaysLu(response);
            } else {
                setErrorMessage(isErrorDispaly(response));
            }
        } catch (error) {
            setErrorMessage(isErrorDispaly(error));
        } finally {
            setLoading(false);
        }
    }
    const getGraphData = async (id, days) => {
        setLoading(true);
        setErrorMessage(null);
        try {
            const graph = await dashboardGraph(walletType, id, days);
            if (graph.ok) {
                setGraphData(graph.data);
            } else {
                setErrorMessage(isErrorDispaly(graph));
            }
        } catch (error) {
            setErrorMessage(isErrorDispaly(error));
        } finally {
            setLoading(false);
        }
    }
    const handleDayClick = (day) => {
        setSelectedDay(day);
    };
    return (
        <div className="layout-bg right-panel mb-16 mb-leftpanel db-portfolio dashboard-port">
            <div className="p-12 custom-flex">
                <AppTitle level={5} className="card-title">Portfolio status</AppTitle>
            </div>
            {errorMessage && <Alert type='error' message={errorMessage} closable={false} className='mb-alert cust-auth-alert' showIcon />}
            <div>
                <div className='graph-btns mb-30'>
                    {
                        daysLu?.data?.Days?.map((days) => (
                            <AppButton
                                key={days?.name}
                                className={selectedDay === days?.name ? 'active' : ''}
                                onClick={() => handleDayClick(days?.name)}
                            >
                                {days?.name}
                            </AppButton>
                        ))
                    }
                </div>
                {loading && <div className="db-kpi p-relative text-center" style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                    <Loader />
                </div>}
                {!loading && <LineChart data={graphData} selectedDay={selectedDay} />}
            </div>
        </div>
    )
}
export default PortfolioStatus