import { ApiControllers } from "../../api/config";
import { apiClient, web3Client } from "../../api/clients";
const walletType = process.env.REACT_APP_WALLET_TYPE;
const getCryptoCoins = async (userid) => {
  let client;
  switch (walletType) {
    case 'non_custodial': {
      client = web3Client
      break;
    }
    case 'custodial': {
      client = apiClient
      break
    }
    default: {
      client = apiClient
    }
  }
  return client.get(ApiControllers.exchangewallet + `Coins/${userid}`);
};
const getFiatWallets = (userId) => {
  return apiClient.get(ApiControllers.exchangewallet + `FiatCoins/${userId}`);
};
const getSummaryInfo = (fiatWallet, coinCode, amount, isCrypto, userId) => {
  return apiClient.get(
    ApiControllers.buySell +
    `Buy/Coins/${fiatWallet}/${coinCode}/${amount}/${isCrypto}/${userId}`
  );
};
const buySave = (obj) => {
  return apiClient.post(ApiControllers.buySell + `Buy`, obj);
};
const fetchCurrencyConvertionValue = (
  coin,
  currency,
  value,
  isCrypto,
  userId
) => {
  return apiClient.get(
    ApiControllers.common +
    `CryptoFiatConverter/${userId}/${coin}/${currency}/${value}/${isCrypto}/Buy`
  );
};
const getBuyCoinDetails = (coin_code) => {
  return apiClient.get(ApiControllers.buySell + `Coins/${coin_code}`);
};
const getProviderDetails = (id) => {
  return web3Client.get(ApiControllers.buySell + `provider/${id}`)
}
export {
  getCryptoCoins,
  getFiatWallets,
  getSummaryInfo,
  buySave,
  fetchCurrencyConvertionValue,
  getBuyCoinDetails,
  getProviderDetails
};
