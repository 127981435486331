import { ApiControllers } from '../../api/config';
import { apiClient, web3Client } from '../../api/clients';
const getWithdrawCustomerBanks=(walletcode)=>{
    return apiClient.get(ApiControllers.exchangewallet+`Exchange/WithdrawFiat/CustomerBanks/${walletcode}`);
}
const getPayeeLu = (currency,customerId) => {
    return apiClient.get(
        ApiControllers.exchangewallet + `Exchange/PayeeLu/${customerId}/${currency}`
    );
};

const confirmTransaction = (obj) => {
    return apiClient.post(ApiControllers.exchangewallet + `Exchange/Withdraw/Fiat/Summary`, obj);
}

const confirmCryptoTransaction = (obj) => {
    return apiClient.post(ApiControllers.exchangewallet + `Crypto/Confirm`, obj);
}

const getFiatSummarySave = (obj) =>{
    return apiClient.post(`ExchangeTransaction/Exchange/Withdraw/Fiat`,obj)
}

const getCode = (isResendOTP,customerId) => {
	return apiClient.get(
		ApiControllers.security +`SendOTP/${customerId}/${isResendOTP}`
	);
};
const getVerification = (code,customerId) => {
	return apiClient.get(
		ApiControllers.security + `/OTPVerification/${customerId}/${code}`
	);
};


const getPayeeCryptoLu = (currency,customerId,network) => {
    return process.env.REACT_APP_WALLET_TYPE!=='non_custodial' ?apiClient.get(
		ApiControllers.common + `PayeeCryptoLU/${customerId}/${currency}/${network}`
	) : web3Client.get(
		ApiControllers.common + `PayeeCryptoLU/${customerId}/${currency}/${network}`);
};
const saveCryptoWithdraw = (obj) => {
    return process.env.REACT_APP_WALLET_TYPE!=='non_custodial' ?apiClient.post(`ExchangeTransaction/ExchangeWithdraw/Crypto`, obj) : web3Client.post(`ExchangeTransaction/Arcana/Withdraw/Crypto`,obj);
}

const getWithdrawmemberCrypto = (customerId,walletType) => {
    let client;
    switch (walletType) {
        case 'non_custodial': {
            client = web3Client
            break;
        }
        case 'custodial': {
            client = apiClient
            break
        }
        default: {
            client = apiClient
        }
    }
    return client.get(ApiControllers.exchangewallet + `Exchange/CryptoWallets/${customerId}`)
}
const getNetworkLu = (walletcode,customerId) => {
    return process.env.REACT_APP_WALLET_TYPE!=='non_custodial' ?apiClient.get(ApiControllers.common + `Wallets/NetWorkLU/${walletcode}/${customerId}`) : web3Client.get(ApiControllers.common + `Wallets/NetWorkLU/${walletcode}/${customerId}`);
}
const getCryptoCoins = async (walletType, id) => {
    let client;
    switch (walletType) {
        case 'non_custodial': {
            client = web3Client
            break;
        }
        case 'custodial': {
            client = apiClient
            break
        }
        default: {
            client = apiClient
        }
    }
    return client.get(ApiControllers.dashboard + `Crypto/${id}`);
}

export { getPayeeLu, confirmTransaction,getFiatSummarySave,
          getCode,getVerification,getWithdrawmemberCrypto,getPayeeCryptoLu,confirmCryptoTransaction,saveCryptoWithdraw,getWithdrawCustomerBanks,getNetworkLu,getCryptoCoins};