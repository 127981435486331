import { ApiControllers } from "../../api/config";
import { apiClient, coinGekoClient, web3Client } from '../../api/clients'
const getCurrencyLU = () => {
    return apiClient.get(ApiControllers.common + `CurrencyLU`);
}
const getFiatCoins = (walletType, id) => {
    let api = walletType === 'non_custodial' ? 'https://stgweb3bankapi.artha.work/api/v1/BuySell/providers' : `${ApiControllers.dashboard}/Fiat/${id}`
    return apiClient.get(api);
}
const totalCryptoCoins = (list) => {
    return list.reduce((acc, cur) => {
        return acc + (cur.convertedValue || 0);
    }, 0);
}
const getCryptoTotal = (cryptoCoinList) => {
    if (cryptoCoinList.length > 0) {
        let _total = totalCryptoCoins(cryptoCoinList);
        return  {
            cryptoBTC: null,
            cryptoBalance: _total,
            fiatBTC: null,
            fiatBalance: null
        }
    }
    else {
        return {}
    }
}
const getTotalFiat = (walletType, id) => {
    return (walletType!=='non_custodial' ? apiClient : web3Client).get(ApiControllers.dashboard + `KPI/User/${id}`);
};
const dashBoardDaysLu = (walletType) => {
    return (walletType!=='non_custodial' ? apiClient : web3Client).get(ApiControllers.dashboard + `DaysLu/DashBoardGraph`)
}
const dashboardGraph = (walletType,id, days) => {
    return (walletType!=='non_custodial' ? apiClient : web3Client).get(ApiControllers.dashboard + `ExchangeDashBoardGraph/${id}/${days}`);
};
const getCryptoDashboard = async (walletType, id) => {
    return (walletType!=='non_custodial' ? apiClient : web3Client).get(ApiControllers.dashboard + `Crypto/${id}`);
}
const fetchMarketCaps = ({ pageNo }) => {
    return coinGekoClient.get(`coins/markets?vs_currency=usd&order=market_cap_desc&per_page=1000&page=${pageNo || 1}&sparkline=false'`)
}
export { getCurrencyLU, getFiatCoins, getTotalFiat, dashboardGraph, getCryptoDashboard, fetchMarketCaps, dashBoardDaysLu, getCryptoTotal }
