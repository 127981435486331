import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js';

const LineChart = ({ data }) => {
  const chartRef = useRef(null);
  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    const chartConfig = {
      type: 'line',
      data: {
        labels: data[0]?.labels,
        datasets: [
          {
            label: '',
            data: data[0]?.values1,
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1
          }
        ]
      },
      options: {
        scales: {
          xAxes: [{
            
            ticks: {
              
              // callback:(props)=>{
              //   if(props.selectedDay==="7Days"){
              //     setMaxLimit(7)
              //   }else if(props.selectedDay==="30Days"){
              //     setMaxLimit(30)
              //   }
              //   else {
              //     setMaxLimit(90)
              //   }
              // },
              maxTicksLimit:30, 
            },
            
            display: true
          }],
          yAxes: [{
            display: true ,// Hide Y axis labels,
            ticks: {
              callback: (value) => {
                if (value === 0.00) {
                  return "0.00"; 
                }
                return (value / 1000).toFixed(1) + 'K';
              },
              stepSize: 5000,
              min: 0.00,
              max: 150000
            }
          }],
        },
        legend: {
          display: false // Hide legend
        }
      }
    };
    const chart=new Chart(ctx, chartConfig);
    
    return () => {
      chart.destroy(); // Cleanup the chart instance when component unmounts
    };
  }, [data[0]]);
  

  return <canvas ref={chartRef} />;
};

export default LineChart;