import React, { Component, useEffect, useState } from "react";
import { Typography, Menu, Layout, Dropdown, Col, Row, Select } from "antd";
import { Link, withRouter } from "react-router-dom";
import DefaultUser from "../assets/images/avathar.png";
import { connect, useDispatch, useSelector } from "react-redux";
import { MenuList, nonCustodialMenuList } from "./menu";
import { clearUserInfo, setGettingUserInfo  } from "../reducers/configReduser";
import { useAuth0 } from "@auth0/auth0-react";
import {  userLogout } from "../reducers/authReducer";
import { clearselectedcoin } from '../../src/components/withdraw.components/reducer'
import { readNotification } from '../components/notifications.component/api';
import apicalls from '../api/apiCalls';
import { setNotificationCount } from '../reducers/dashboardreducer';
import { checkCustomerState } from '../utils/service';
import Notifications from '../components/notifications.component/index.js'
import PropTypes from 'prop-types';
import ArcanaConnectCard from "../auth/web3LoginButton.jsx";
import { store } from "../store/index.js";
import CopyToClipboard from "react-copy-to-clipboard";
import AppText from "../shared/appText.js";
import AppButton from "../shared/appButton.js";
import Loader from "../shared/loader.js";
import useSwitchNetwork from "../utils/useSwitchNetwork.js";
import { useDisconnect } from "wagmi";
import { arcanaAuth } from "../auth/web3Config.js";
const { Text, Paragraph, Title } = Typography;
const { Sider } = Layout;
const { Option } = Select;
const WalletType = process.env.REACT_APP_WALLET_TYPE;
if (WalletType === 'non_custodial') {
  window.onload = async function () {
    try {
      await arcanaAuth?.init()
    } catch (error) {
    }
  };
}
const LogoutApp = () => {
  const { logout } = useAuth0()
  const dispatch = useDispatch()
  const clearEvents = () => {
    dispatch(clearUserInfo());
    dispatch(userLogout());
    window.$zoho?.salesiq?.chat.complete();
    window.$zoho?.salesiq?.reset();
    logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_REDIRECT_URI
      }
    });
  }
  return (<li className="head-button c-pointer" onClick={clearEvents} >
    <Text className="head-exit">Logout</Text></li>)
}
const LogoutWeb3App = () => {
  const { gettingUserInfo } = useSelector(state => state.userConfig)
  const { disconnectAsync } = useDisconnect()
  const clearEvents = async () => {
    store.dispatch(setGettingUserInfo('disconnecting'))
    try {
      await disconnectAsync()
    } catch (error) {
      store.dispatch(setGettingUserInfo(''))
    }
  }
  return (<li className="">
    {gettingUserInfo === 'disconnecting' && <Text className="text-link">Disconnecting....</Text>}
    {!gettingUserInfo && <AppButton className="disconnect-btn" onClick={clearEvents} disabled={gettingUserInfo}>
      <Text className="head-exit">Disconnect</Text></AppButton>}
  </li>)
}
const Network = ({ isOpen }) => {
  const [currentNetwork, setCurrentNetwork] = useState(null);
  const [selectedNetwork, setSelectedNetwork] = useState(null)
  const { switching, switchNetwork } = useSwitchNetwork()
  const { arcanaChains: supportedNetworks, gettingUserInfo } = useSelector((state) => state.userConfig)
  useEffect(() => {
    getNetwork()
  }, [arcanaAuth.provider.chainId, supportedNetworks])
  useEffect(() => {
    if (!isOpen) {
      setSelectedNetwork(currentNetwork || null)
    }
  }, [isOpen, currentNetwork])
  const handleNetworkSelection = (value) => {
    setSelectedNetwork(value)
  }
  const getNetwork = () => {
    const id = arcanaAuth.provider.chainId;
    const network = supportedNetworks.data?.find(networkChain => networkChain.chain_id == id)
    setCurrentNetwork(network?.name)
  }
  return <>
    {switching && <div className="network-loader"><Loader /></div>}
    {!switching && <Text className="current-network">{currentNetwork || '--'}</Text>}
    <div className="switch-network">
      <Select loading={switching} value={selectedNetwork || currentNetwork || null} className="change-network" onChange={(e) => handleNetworkSelection(e)} placeholder="Select network">
        {supportedNetworks?.data?.map((network) => {
          return <Option key={network.chain_id} value={network.chain_id}>{network.name}</Option>
        })}
      </Select>
      <AppButton onClick={async () => {
        await switchNetwork(selectedNetwork)
        setSelectedNetwork(null)
      }} className='switch-btn' disabled={gettingUserInfo === 'disconnecting'}>Switch</AppButton>
    </div>

  </>
}
class AppHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowSider: false,
      isWeb2: process.env.REACT_APP_WALLET_TYPE === "custodial",
      triggerLogin: false,
      web3Info: {},
      collapsed: false,
      isProfileDropdownVisible: false,
      open: false,
      showNotificationsDrawer: false,
      headerList: process.env.REACT_APP_WALLET_TYPE !== 'non_custodial' ? MenuList : nonCustodialMenuList
    };
  }
  filterDropdown = (isVisible) => {
    return (
      <>
        {" "}
        <div className="profile-dropdown-menu">
          <img
            src={
              this.props.userConfig?.imageURL
                ? this.props.userConfig?.imageURL
                : DefaultUser
            }
            className="dropdown-img"
            alt={"user profile"}
          />
          <Paragraph className="head-username">
            {this.props.userConfig?.isBusiness ? (
              this.props.userConfig?.businessName
            ) : (
              <>
                {this.props.userConfig?.firstName}{" "}
                {this.props.userConfig?.lastName}
              </>
            )}
          </Paragraph>
          {!this.state.isWeb2 &&
            <div>
              <div className="text-primary d-flex align-items-center w-fit-auto">
                {/* <img className="network-image" src="https://cdn.moralis.io/eth/0x.png" alt="ETH" width="20px" height="20px" /> */}
                {this.props.web3User?.address && <CopyToClipboard text={this.props.web3User?.address} options={{ format: 'text/plain' }}><AppText copyable={{ tooltips: ['Copy', 'Copied'] }} className="summary-text m-0 text-center"> {this.props.web3User?.address?.substring(0, 4) + "...." + this.props.web3User?.address?.slice(this.props.web3User?.address?.length - 4)}</AppText></CopyToClipboard>}
              </div>
              <Network isOpen={isVisible} />
            </div>
          }
          {this.state.isWeb2 &&
            <Text block className="estimate-text text-primary" onClick={() => { this.redirecttoProfile() }}>
              Manage Account
            </Text>
          }

          <ul className="drpdwn-list">
            {this.state.isWeb2 && <LogoutApp />}
            {!this.state.isWeb2 && <LogoutWeb3App />}
          </ul>
        </div>
      </>
    );
  };
  redirecttoProfile = () => {
    window.open(`${process.env.REACT_APP_PROFILE_URL}/userprofile`, "_blank");
  }
  redirectDashboard = () => {
    this.props.history.push("/dashboard");
    this.setState({ isShowSider: false, collapsed: false });
  };
  redirectTrade = () => {
    this.props.history.push("/trade");
    this.setState({ isShowSider: false, collapsed: false });
  };
  redirectDeposit = () => {
    this.props.history.push("/deposit");
    this.setState({ isShowSider: false, collapsed: false });
    this.props.dispatch(clearselectedcoin())
  };
  redirectWithdraw = () => {
    this.props.history.push("/withdraw");
    this.setState({ isShowSider: false, collapsed: false });
    this.props.dispatch(clearselectedcoin())
  };
  redirectTranscation = () => {
    this.props.history.push("/transactions");
    this.setState({ isShowSider: false, collapsed: false });
  };
  readNotifications = () => {
    let currentDate = new Date().toISOString();
    let obj = {
      isRead: true,
      readDate: currentDate
    };
    let appName = WalletType === 'non_custodial' ? "Web3Exchange" : "exchange";
    this.props.dispatch(setNotificationCount(0));
    readNotification(this.props.userConfig?.id, obj, appName).then(() => {
      this.props.dispatch(setNotificationCount(0));
    });
    apicalls.encryptValue("true", this.props.userConfig?.sk);
  }
  showToggle = () => {
    this.setState(prevState => ({
      ...prevState,
      isShowSider: true,
      collapsed: !prevState.collapsed
    }));
  };
  openModal = () => {
    this.setState(prevState => ({ ...prevState, open: true }));
  };
  showFeatures = () => {
    return !!window.location?.pathname?.includes('dashboard/')
  }
  activeFeature = (Type) => {
    if (window.location?.pathname.includes('transactionDetails')) {
      return 'transactions'
    } else if (window.location?.pathname.includes(Type)) {
      return Type
    } else if (window.location?.pathname == "/") {
      return 'dashboard'
    }
  }
  redirectto = (path) => {
    this.props.dispatch(clearselectedcoin())
    if (!this.props.userConfig?.isEmailVerified && this.state.isWeb2) {
      this.props.history.push("/emailVerification");
    } else if (!this.props.userConfig?.isCustomerUpdated && this.state.isWeb2) {
      this.props.history.push("/auth0");
    } else if (!this.props.userConfig?.isPhoneNumberVerified && this.state.isWeb2) {
      this.props.history.push("/phoneVerification");
    }
    else if (!checkCustomerState(this.props.userConfig) && this.state.isWeb2) {
      this.props.history.push("/sumsub");
    }
    else if (!this.props.userConfig?.isKYC && this.state.isWeb2) {
      this.props.history.push("/notkyc");
    }
    else if (window.location?.pathname.includes(path)) {
      return;
    }
    else if (!this.props.userConfig?.id && !this.state.isWeb2) {
      this.props.history.replace("/welcome");
      this.setState(prevState => ({ ...prevState, triggerLogin: true }));
    } else {
      this.setState(prevState => ({ ...prevState, triggerLogin: false }));
      this.props.history.push(path)
    }
  }
  redirection = (prop) => {
    if (prop === 'cards')
      window.open(process.env.REACT_APP_CARDS_URL);
    else if (prop === 'bank')
      window.open(process.env.REACT_APP_BANK_URL);
    else if (prop === 'wallet')
      window.open(process.env.REACT_APP_WALLET_URL);
  }
  redirectionDropdown = () => {
    return (

      <div className="wallet-item c-pointer">
        <Title className="super-title">Apps</Title>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={8} >
            <div className="c-pointer wallet-menu" onClick={() => this.redirection('bank')}>
              <span className="icon bank"></span>
              <div>
                <Title className="supermenu-title">Bank</Title>
                <p className="text-description">Digital banks rely solely on mobile apps and websites, eliminating the need for physical branches or in-person interaction.</p>
              </div>
            </div>
          </Col>

          <Col xs={24} sm={24} md={8} >
            <div className="c-pointer wallet-menu" onClick={() => this.redirection('wallet')}>
              <span className="icon wallet-icon"></span>
              <div>
                <Title className="supermenu-title">Wallet</Title>
                <p className="text-description">Wallets is a secure & convenient digital tool for storing, managing & transacting with crypto using encryption techniques.</p>
              </div>
            </div></Col><Col xs={24} sm={24} md={8} onClick={() => this.redirection('cards')}>
            <div className="c-pointer wallet-menu">
              <span className="icon cards"></span>
              <div>
                <Title className="supermenu-title">Cards</Title>
                <p className="text-description">Cards, like debit cards, offer individuals a secure & convenient way to make purchases & access funds.</p>
              </div>
            </div></Col>
        </Row>
      </div>

    );
  };
  handleNotificationsDrawer = () => {
    if (this.props.userConfig?.id) {
      this.setState({ ...this.state, showNotificationsDrawer: true })
      return;
    }
    this.setState(prevState => ({ ...prevState, triggerLogin: true }));
  }
  render() {
    return (
      <>
        <Layout className="layout sticky-header">
          <div className="main-container">
            <div className="custom-header" id="area">
              <img src={"https://devdottstoragespace.blob.core.windows.net/neoimages/nbsimple_white_logo.svg"} alt={"error"} className="customlogo" />

              <ul className='menu-item main-menu mobile-dt-header'>
                {this.state.headerList.map((val) =>
                  <li key={val.path} onClick={() => this.redirectto(val.path)} className={"list-item" + ' ' + (val.activePath == this.activeFeature(val.activePath) ? 'active' : '')}><Link
                    to="">{val.title}</Link></li>
                )}
              </ul>
              <Menu
                inlineCollapsed={false}
                theme="light"
                mode="horizontal"
                className="header-right mobile-headerview"
              >

                {WalletType !== 'non_custodial' &&
                  <Menu.Item>
                    <Dropdown
                      className="mr-12 c-pointer"
                      overlayClassName="secureDropdown super-menu"
                      overlay={this.redirectionDropdown}
                      placement="bottomRight"
                      arrow
                    >
                      <span className="icon lg menu"></span>
                    </Dropdown>
                  </Menu.Item>}

                <Menu.Item key="2" className='list-item'
                  onClick={() => this.handleNotificationsDrawer()}
                >
                  <span
                    className="icon lg bell"
                    onClick={() => this.readNotifications()}
                  >
                    {this.props?.dashboardReducer != null &&
                      this.props?.dashboardReducer !== 0 && (
                        <span className="count-note">{this?.props?.dashboardReducer}</span>
                      )}
                  </span>
                </Menu.Item>

                {<Menu.Item key="3" className="list-item">
                  {this.props.userConfig?.id && <Dropdown
                    className="mr-12 c-pointer"
                    overlayClassName="secureDropdown"
                    overlay={() => this.filterDropdown(this.state.isProfileDropdownVisible)}
                    placement="bottomRight"
                    arrow
                    trigger={'click'}
                    onVisibleChange={(value) => this.setState(prev => ({ ...prev, isProfileDropdownVisible: value }))}
                  >
                    <div className="profile-dropdown">
                      {this.props.userConfig?.imageURL != null && (
                        <img
                          src={
                            this.props.userConfig?.imageURL
                              ? this.props.userConfig?.imageURL
                              : DefaultUser
                          }
                          className="login-user-img"
                          alt={"user profile"}
                        />
                      )}
                      {(this.props.userConfig?.imageURL === null || !this.props.userConfig?.imageURL) && (
                        <img
                          src={
                            this.props.userConfig?.imageURL
                              ? this.props.userConfig?.imageURL
                              : DefaultUser
                          }
                          className="login-user-img"
                          alt={"user profile"}
                        />
                      )}
                    </div>
                  </Dropdown>}
                </Menu.Item>}
                <Menu.Item
                  key="4"
                  className="list-item visible-mobile"
                  onClick={this.showToggle}
                >
                  <span className="icon lg hamburg-icon " />
                </Menu.Item>
                {!this.state.isWeb2 && !this.props.userConfig?.id && <Menu.Item key={"5"} className="list-item">
                  <ArcanaConnectCard onLogin={(info) => { this.setState({ ...this.state, web3Info: info }) }} triggerLogin={this.state.triggerLogin} resetTrigger={(payload) => this.setState(prevState => ({ ...prevState, triggerLogin: payload }))} />
                </Menu.Item>}
              </Menu>

            </div>
            {this.state.isShowSider && (
              <Sider
                trigger={null}
                collapsible
                collapsed={this.state.collapsed}
                collapsedWidth={0}
                className={` ${this.state.collapsed ? "" : "sideropen"}`}
              >
                <div className="mobile-header" id="area">
                  <ul className='menu-item main-menu mobile-dt-header'>
                    {this.state.headerList.map((val) =>
                      <li key={val.path} onClick={this.redirectto} className={"list-item" + ' ' + this.activeFeature(val.activePath)}><Link to={`${val.path}`}
                      >{val.title}</Link></li>
                    )}
                  </ul>
                </div>
              </Sider>
            )}
          </div>

        </Layout>
        {this.state.showNotificationsDrawer && (
          <Notifications
            showDrawer={this.state.showNotificationsDrawer}
            onClose={() => this.setState({ ...this.state, showNotificationsDrawer: false })}
          />
        )}
      </>
    );
  }
}
const connectStateToProps = ({ userConfig, dashboardReducer }) => {
  return { userConfig: userConfig.userProfileInfo, web3User: userConfig.web3User, dashboardReducer: dashboardReducer.notificationCount, supportedNetworks: userConfig.arcanaChains };
};
const connectDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}
AppHeader.propTypes = {
  userConfig: PropTypes.shape({
    imageURL: PropTypes.string,
    isBusiness: PropTypes.bool,
    businessName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    id: PropTypes.string,
    sk: PropTypes.string,
    isEmailVerified: PropTypes.bool,
    isCustomerUpdated: PropTypes.bool,
    isPhoneNumberVerified: PropTypes.bool,
    isKYC: PropTypes.bool,
  }),
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
};

export default connect(connectStateToProps, connectDispatchToProps)(withRouter(AppHeader));
