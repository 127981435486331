import { ApiControllers } from "../../api/config";
import { apiClient, web3Client } from '../../api/clients'
const walletType = process.env.REACT_APP_WALLET_TYPE;
const sellCryptoCoinList = async (userId) => {
    let client;
    switch (walletType) {
        case 'non_custodial': {
            client = web3Client
            break;
        }
        case 'custodial': {
            client = apiClient
            break
        }
        default: {
            client = apiClient
        }
    }
    return client.get(ApiControllers.buySell + `sell/${userId}`);
}
const getFiatWallets = () => {
    return apiClient.get(ApiControllers.wallets + `Fiat`);
}
const sellCryptoFiatConverter = (cryptoCoins, fiatCurency, value, flag, userId) => {
    return apiClient.get(ApiControllers.common + `CryptoFiatConverter/${userId}/${cryptoCoins}/${fiatCurency}/${value}/${flag}/sell`);
}

const sellSaveObj = (fromCoin, toCoin, fromAmount, userId, flag) => {
    return apiClient.get(ApiControllers.buySell + `Sell/Coins/${fromCoin}/${toCoin}/${fromAmount}/${flag}/${userId}`)
}

const sellSummarySaveObj = (obj) => {
    return apiClient.post(ApiControllers.buySell + `Sell`, obj)
}
const getProviderDetails = (id) => {
    return web3Client.get(ApiControllers.buySell + `provider/${id}`)
}
export { getProviderDetails, sellCryptoCoinList, sellCryptoFiatConverter, sellSaveObj, sellSummarySaveObj, getFiatWallets };