import { ApiControllers } from "./config";
import CryptoJS from "crypto-js";
import { apiClient, gridClient, ipStackClient, web3Client } from './clients'
import { arcanaAuth } from "../auth/web3Config"; 
const getportfolio = () => {
	return process.env.REACT_APP_WALLET_TYPE !=='non_custodial' ?apiClient.get(ApiControllers.wallets + `Crypto`) : web3Client.get(ApiControllers.wallets + `Crypto`);
};
const transactionGrid = (customerId, filters, page, pageSize) => {
	return gridClient.get(`ExchangeTransaction/transactions/${customerId}/${filters.type}/${filters.wallet}/${filters.searchQuery}/${filters.dates[0]}/${filters.dates[1]}?page=${page}&pageSize=${pageSize}`)
}
const searchTransactions = (customerId, type) => {
	return gridClient.get(`ExchangeTransaction/transactions/${customerId}/${type}`)
}

const transactionDetails = (id) => {
	return gridClient.get(`ExchangeTransaction/transaction/${id}`)
}

const getCryptos = () => {
	return process.env.REACT_APP_WALLET_TYPE !=='non_custodial' ?apiClient.get(ApiControllers.buySell + "Coins") : web3Client.get(ApiControllers.buySell + "Coins");
};
const getCryptoCoins = () => {
	return process.env.REACT_APP_WALLET_TYPE !=='non_custodial' ?apiClient.get(ApiControllers.wallets + `CryptoPortFolio/Exchange`) : web3Client.get(ApiControllers.wallets + `CryptoPortFolio/Exchange`);
}
const getFiatCoins = (id) => {
	return process.env.REACT_APP_WALLET_TYPE !=='non_custodial' ?apiClient.get(ApiControllers.exchangewallet + `Exchange/FiatWallets/${id}`) :  web3Client.get(ApiControllers.exchangewallet + `Exchange/FiatWallets/${id}`);
}
const getMember = () => {
	return process.env.REACT_APP_WALLET_TYPE !=='non_custodial' ?apiClient.get(ApiControllers.registration + `App/Exchange`) : web3Client.get(ApiControllers.registration + `App/Exchange`);
};

const sumsublivenessacesstoken = (userid, flow) => {
	return apiClient.get(
		"Kyc/ExternalAccessToken?userId=" + userid + "&levelName=" + flow
	);
};

const downloadKyc = () => {
	return apiClient.get(ApiControllers.customers + `DownloadFile`);
};
const encryptValue = (msg, key) => {
	msg = typeof msg == "string" ? msg : JSON.stringify(msg);
	let salt = CryptoJS.lib.WordArray.random(128 / 8);

	let key1 = CryptoJS.PBKDF2(key, salt, {
		keySize: 256 / 32,
		iterations: 10,
	});

	let iv = CryptoJS.lib.WordArray.random(128 / 8);

	let encrypted = CryptoJS.AES.encrypt(msg, key1, {
		iv: iv,
		padding: CryptoJS.pad.Pkcs7,
		mode: CryptoJS.mode.CBC,
	});
	return salt.toString() + iv.toString() + encrypted.toString();
};
const twofactor = (custid) => {
	return apiClient.get(ApiControllers.customers + `twofactor/${custid}`);
};
const getPayeeLu = (currency) => {
	return apiClient.get(
		ApiControllers.addressbook + `PayeeLu/${currency}`
	);
};
const getOrderHistoryGrid = (apiMethod, pageNo, pageSize) => {
	return gridClient.get(`${apiMethod}/?page=${pageNo}&pageSize=${pageSize}`)
}
const sumsubacesstoken = (userid, flow) => {
	return apiClient.get(
		"Kyc/AccessToken1?applicantId=" + userid + "&levelName=" + flow
	);
};
const updateKyc = (userid) => {
	return apiClient.put(ApiControllers.customers + `${userid}/KYC`);
};

const getIpStock = () => {
	return ipStackClient.get('check?access_key=f16009057980d892d3b078963e5c51af')
}
const transactionDetailsDwd = (id, type) => {
	return gridClient.get(ApiControllers.exchangeTransaction + `TemplatesTranction/${id}/${type}`)
}
const amtVerification = (customerid, currency, amount, bankId) => {
	return apiClient.get(ApiControllers.exchangeTransaction + `Verifications/${customerid}/${bankId}/${currency}/${amount}`)
}
const cryptoAmtVerification = (customerId, currency, amount, networkId, network) => {
	return apiClient.get(ApiControllers.exchangeTransaction + `Verifications/${customerId}/${currency}/${amount}/${networkId}/${network}`)
}
const handleWeb3User = ({ address, loginToken }) => {
	if(loginToken){
		return web3Client.get(ApiControllers.customers + "customerCreation/" + address, {}, {
			headers: {
				authorization:loginToken
			}
		})
	}
}

const getArcanaChains=()=>{
	return fetch(`${arcanaAuth?.networkConfig?.gatewayUrl}/api/v1/chains/${process.env.REACT_APP_ARCANA_ID}`)
}
const getTransactionTypes=()=>{
	return web3Client.get(`Common/TrasactionTypeLu`)
}
const transactionWallets=(id)=>{
	return web3Client.get(`ExchangeTransaction/customerwalletLu/${id}`)
}
const apicalls = {
	getportfolio,
	getCryptos,
	getMember,
	sumsublivenessacesstoken,
	encryptValue,
	downloadKyc,
	twofactor,
	getPayeeLu, getCryptoCoins, getFiatCoins, getOrderHistoryGrid, transactionGrid,
	sumsubacesstoken, updateKyc, searchTransactions, transactionDetails, getIpStock, transactionDetailsDwd, amtVerification,
	cryptoAmtVerification, handleWeb3User,
	getArcanaChains,
	getTransactionTypes,
	transactionWallets
};
export default apicalls;
