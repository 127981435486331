import React, { useEffect, useState } from 'react'
import { Layout as AntLayout } from 'antd';
import AppContent from './content';
import AppFooter from './appFooter';
import AppHeader from './header';
import OnBoarding from './onboard.component';
import { connect } from "react-redux";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import { profileSuccess, setToken, userLogout } from "../reducers/authReducer";
import { useAccount, useConnect } from 'wagmi';
import { arcanaAuth } from '../auth/web3Config';
import { store } from '../store';
import { clearUserInfo, getArcanaSupportedChains, handleWeb3Login, setGettingUserInfo, setLoginError, setWeb3User } from '../reducers/configReduser';
import { isErrorDispaly } from '../neo/components/validationsAndExceptions/apiExceptions';
const Layout = (props) => {
  const { isLoading: web3Loader } = useConnect()
  const { isConnecting, isReconnecting } = useAccount();
  const history = useHistory()
  const { pathname } = useLocation()
  const [isWeb3] = useState(process.env.REACT_APP_WALLET_TYPE === "non_custodial");
  const { isAuthenticated, loginWithRedirect, user, getAccessTokenSilently, error, isLoading } = useAuth0();
  const callApi = async () => {
    try {
      const token = await getAccessTokenSilently();
      props.acctoken(token)
    } catch (errorMsg) {
    }
  };
  useEffect(() => {
    if (isWeb3) {
      arcanaAuth?.provider?.on("connect", handleArcanaConnect);
      arcanaAuth?.provider?.on("disconnect", handleArcanaDisconnect);
    }
    else {
      if (!isAuthenticated) {
        loginWithRedirect({ redirect_uri: process.env.REACT_APP_REDIRECT_URI });
      } else {
        props.updateProfile(user)
        callApi()
      }
    }
    return () => {
      if (isWeb3) {
        arcanaAuth?.provider?.removeListener("connect", handleArcanaConnect);
        arcanaAuth?.provider?.removeListener("disconnect", handleArcanaDisconnect);
      }
    }
  }, [isWeb3])
  useEffect(() => {
    if (isWeb3 && !props.userProfile?.id && !isReconnecting && !isConnecting && !props.gettingUserInfo && !web3Loader) {
      history.push('/welcome');
    }
    if (isWeb3 && pathname.includes('/welcome') && props.userProfile?.id) {
      history.replace('/dashboard');
    }
  }, [isWeb3, props.userProfile?.id, web3Loader, pathname, isReconnecting, isConnecting, props.gettingUserInfo])

  useEffect(() => {
    if (props.userProfile?.id && isWeb3) {
      store.dispatch(getArcanaSupportedChains())
    }
  }, [props.userProfile?.id, isWeb3])
  if (!isWeb3) {
    if (!isAuthenticated || !props.oidc.profile || !props.oidc.deviceToken) {
      return <div className="loader">Loading .....</div>;
    } else if (props.oidc.profile && !props.userProfile) {
      return <OnBoarding />;
    } else if (props.twoFA?.loading) {
      return <div className="loader">Loading .....</div>;
    } else if (error) {
      return <div>Oops... {error.message}</div>;
    } else if (isLoading) {
      return <div className="loader">Loading .....</div>;
    }
  }

  const handleArcanaConnect = async () => {
    store.dispatch(setGettingUserInfo('connecting'))
    const userInfo = await arcanaAuth.getUser();
    const isLoggedIn = await arcanaAuth.isLoggedIn();
    const info = { ...isLoggedIn, ...userInfo }

    store.dispatch(setToken(info?.loginToken));
    store.dispatch(handleWeb3Login(info, () => {
      store.dispatch(setGettingUserInfo(''))
      store.dispatch(setWeb3User(info));
    },
      (responce) => {
        store.dispatch(setGettingUserInfo(''))
        store.dispatch(setLoginError(isErrorDispaly(responce)));
      }
    ));
  };
  const handleArcanaDisconnect = async () => {
    const isLoggedIn = await arcanaAuth.isLoggedIn();
    store.dispatch(setWeb3User({ isLoggedIn }));
    store.dispatch(setToken(null));
    store.dispatch(clearUserInfo());
    store.dispatch(userLogout());
    store.dispatch(setGettingUserInfo(''))
  };
  return (<AntLayout>
    <AppHeader />
    <div style={{ zIndex: 100, }}>
      <AppContent />
    </div>
    <AppFooter />
  </AntLayout>)
}
const connectStateToProps = ({ oidc, userConfig }) => {
  return { oidc, userProfile: userConfig.userProfileInfo, gettingUserInfo: userConfig.gettingUserInfo }

}
const connectDispatchToProps = dispatch => {
  return {
    updateProfile: (info) => { dispatch(profileSuccess(info)) },
    acctoken: (data) => { dispatch(setToken(data)) },
    dispatch
  }

}
export default connect(connectStateToProps, connectDispatchToProps)((withRouter(Layout)));
