import { useEffect } from "react";
import AppButton from "../shared/appButton";
import { useSelector } from "react-redux";
import { useAccount, useConnect } from "wagmi";

export default function Web3LoginButton(props) {
  const { connectAsync, connectors, isLoading } = useConnect();
  const { isConnecting, isReconnecting } = useAccount();
  const { gettingUserInfo } = useSelector(
    (store) => store.userConfig
  );
  useEffect(() => {
    if (props.triggerLogin) {
      handleLogin();
      props.resetTrigger(false);
    }
  }, [props.triggerLogin]);
  const handleLogin = async () => {
    try {
      const response = await connectAsync({ connector: connectors[0] });
      await props.onSuccess?.(response);
    } catch (err) {
      await props.onError?.(err);
    }
  };

  return (
    <AppButton
      className={props.buttonClassName || "login-btn"}
      onClick={() => handleLogin()}
    >
      {isLoading || isConnecting || isReconnecting || gettingUserInfo ? (
        "Connecting ..."
      ) : (
        <span className="d-flex gap16">
          <span>Login</span>
          {props.hasIcon && props.IconComponent}
        </span>
      )}
    </AppButton>
  );
}
