import React, { useEffect, useState } from 'react';
import { Input, List, Typography, Skeleton, Dropdown, Space, Menu, Alert } from 'antd';
import { connect } from 'react-redux';
import AppNumber from '../../shared/inputs/appNumber';
import { Link, useParams, useHistory,useLocation } from 'react-router-dom';
import Loader from '../../shared/loader';
import FormatToEightDecimals from '../../utils/formatToEightDecimals';
const environment = process.env.wallet_type || 'non_custodial'

const CoinsList = ({ fiatCoin, coinList, coinSearch, isLoading, selectedCoin, onReturnCoin,
     selectCoin, isPercent, isShowCoinBalance, CryptoCoin, cryptoCoin, isNetwork, isCrypto,
      fiatWalletCode, coinFields, selectionWalletcode, coinSelectionModal, selectedWithdrawCoin,
       errorMessage, screenType, ShowBalance,isSelectLeftPanel,dropdownhide,handleMenuBarDropdown }) => {
    const [coinListData, setCoinListData] = useState(coinList);
    const [searchVal, setSearchVal] = useState([]);
    const [coinSelectedModal, setCoinSelectedModal] = useState(null);
    const { walletcode, walletCode } = useParams();
    const history = useHistory();
    let location=useLocation()
    const menuBar = (item, currencyCode,id) => (
        <Menu>
            <ul className="dropdown-list">
                {item == "crypto" && <>
                {!handleMenuBarDropdown && <> 
                    <Link to={`/buy/${currencyCode}/${id}`}
                        className='text-center customicon c-pointer linktitle-space'>Buy</Link>

                    <Link to={`/sell/${currencyCode}/${id}`}
                        className='text-center customicon c-pointer'>Sell</Link>
                    </>}
                    {(handleMenuBarDropdown || environment !=='non_custodial') &&<> 
                    <Link
                        to={`/deposit/crypto/${currencyCode}`}
                        className='text-center customicon c-pointer'>Deposit</Link>

                    <Link
                        to={`/withdraw/crypto/${currencyCode}`}
                        className='text-center customicon c-pointer'>Withdraw</Link> </> }
                    
                </>}
                {item === "fiat" && <>
                    <Link
                        to={`/deposit/fiat/${currencyCode}`}
                        className='text-center customicon c-pointer linktitle-space'>Deposit</Link>

                    <Link
                        to={`/withdraw/fiat/${currencyCode}`}
                        className='text-center customicon c-pointer'>Withdraw</Link>

                </>}
            </ul>
        </Menu>
    )
    useEffect(() => {
        setCoinListData(coinList)
    }, [coinList])
    useEffect(() => {
        setCoinSelectedModal(null);
    }, [walletcode, walletCode])
    useEffect(() => {
        if (selectedCoin) {
            if (!onReturnCoin) {
                selectList(selectedCoin)
            }
        }
        if (selectedWithdrawCoin?.walletCode) {
            setCoinSelectedModal(selectedWithdrawCoin?.walletCode)
            if (screenType === "dashboard") {
                setCoinSelectedModal(null)
            }
            else {
                setCoinSelectedModal(selectedWithdrawCoin?.walletCode)
            }
        }
    }, [selectedCoin, selectedWithdrawCoin]);//eslint-disable-line react-hooks/exhaustive-deps
    const handleSearch = (value) => {
        let filtercoinsList;
        if (!value) {
            filtercoinsList = coinList;
        } else {
            filtercoinsList = coinList.filter(item => ((item[coinFields?.currencyCode])?.toLowerCase().includes(value.toLowerCase())))

        }
        setCoinListData(filtercoinsList)
    }
    const selectList = (item) => {
       if(isSelectLeftPanel){
        return true;
       }
        if (selectCoin) {
            selectCoin(item)
            if (screenType === "withdrawFiat")
                history.push(`/withdraw/fiat/${item?.walletCode.toUpperCase()}`)
            else if (screenType === "withdrawCrypto") {
                history.push(`/withdraw/crypto/${item?.walletCode.toUpperCase()}`)
            }
        }
        else if (coinSelectionModal) {
            coinSelectionModal(item);
            setCoinSelectedModal(item?.walletCode || item?.coin || fiatCoin || item?.coinCode);
        }

    }

    const { Search } = Input;
    const { Text } = Typography

    let inputPlaceHolder = window?.location?.pathname.split("/")[2];
    const getPlaceholderText = () => {
        switch (inputPlaceHolder) {
            case "fiat":
                return 'Search Currency';
            case "crypto":
                return 'Search Coin';
            default:
                return 'Search Coin';
        }
    };

    return (

        <div className="coin-container">
            {errorMessage && <Alert type='error' message={errorMessage} closable={false} className='mb-alert cust-auth-alert' showIcon />}
            {coinSearch && <div className="coin-search dt-topspace">
                <Search value={searchVal} placeholder={getPlaceholderText()} suffix={<span className="icon md search c-pointer" />}
                    onChange={({ currentTarget }) => { setSearchVal(currentTarget.value); handleSearch(currentTarget.value) }} size="middle" bordered={false} className="coin-search-input " />
            </div>}
            {isLoading && <div className="db-kpi p-relative text-center" style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                <Loader />
            </div>}


            {!isLoading &&
                <List
                    className={`${(environment != 'non_custodial' || location?.pathname?.includes('/deposit/crypto') || location?.pathname?.includes('/withdraw/crypto')) ? '' : 'deposit-list'}`}
                    dataSource={coinListData}
                    renderItem={(item) => (

                        <List.Item key={item.id} className={`coin-item coin-flexgrow db-coinlist ${((item[coinFields?.currencyCode]?.toLowerCase()) === ( (environment != 'non_custodial' || location?.pathname?.includes('/deposit/crypto') || location?.pathname?.includes('/withdraw/crypto') )  && (coinSelectedModal?.toLowerCase() || fiatCoin?.toLowerCase() || CryptoCoin?.coin.toLowerCase() || cryptoCoin?.toLowerCase() || walletcode?.toLowerCase() || fiatWalletCode?.toLowerCase() || selectionWalletcode?.toLowerCase()))) ? 'active' : ''}`} onClick={() => selectList(item)}
                            actions={
                                coinFields?.showTradebtn ? [<>
                                    {(item[coinFields?.currencyCode] === "USD" || item[coinFields?.currencyCode] === "EUR" || item[coinFields?.currencyCode] === "GBP" || item[coinFields?.currencyCode] === "CHF" || item[coinFields?.currencyCode] === "SGD") ?

                                        <Dropdown
                                            overlay={menuBar("fiat", item[coinFields?.currencyCode],item?.id)}
                                            trigger={['click']} placement="bottomCenter" arrow overlayClassName="secureDropdown depwith-drpdown" >
                                            <span onClick={e => e.preventDefault()}>
                                                <Space>
                                                    <span className="icon md menubar"></span>

                                                </Space>
                                            </span>
                                        </Dropdown>
                                        : !dropdownhide &&
                                        <Dropdown
                                            overlay={menuBar("crypto", item[coinFields?.currencyCode],item?.id)}
                                            trigger={['click']} placement="bottomCenter" arrow overlayClassName="secureDropdown depwith-drpdown" >
                                            <span onClick={e => e.preventDefault()}>
                                                <Space>

                                                    <span className="icon md menubar"></span>

                                                </Space>
                                            </span>
                                        </Dropdown>
                                    }
                                </>
                                ] : ''
                            }
                        >
                            <Skeleton loading={isLoading} active avatar paragraph={{ rows: 1 }}>

                                <List.Item.Meta
                                    bordered
                                    actions={[]}
                                    avatar={<div className=' '>{
                                         <span className={`crypto coin sm ${item[coinFields?.currencyCode]?.toLowerCase()}`} />
                                    }
                                    </div>}
                                    title={<Text className={`coin-title non-cust-cointitle`}>{item[coinFields?.currencyCode]}</Text>}
                                    description={<>{isCrypto && <div className='coin-fillname'>
                                        {item[coinFields?.currencyFullName]}<Text className={` fw-600 text-upper text-secondary d-block`}></Text>
                                    </div>}</>}
                                />
                                {!ShowBalance && <div className={` ${isShowCoinBalance === true ? "d-nonecoinlist text-right" : "d-coinlist text-right"}`}>
                                    <AppNumber className={`coin-val d-block coinval-width`}
                                        defaultValue={Math.abs(item[coinFields?.amount]) > 999999 ? FormatToEightDecimals(Math.sign(item[coinFields?.amount]) * ((Math.abs(item[coinFields?.amount]) / 1000000).toFixed(1)) ) : FormatToEightDecimals( Math.sign(item[coinFields?.amount]) * Math.abs(item[coinFields?.amount]) )} suffixText={Math.abs(item[coinFields?.amount]) > 999999 ? "M" : null} prefix={(item[coinFields?.currencyCode] === "USD" ? "$" : null) || (item[coinFields?.currencyCode] === "GBP" ? "£" : null) || (item[coinFields?.currencyCode] === "EUR" ? "€" : null)} thousandSeparator={true} />                                  
                                    {isPercent &&
                                         <Text className={`coin-price ${item[coinFields?.percentage] < 0 ? 'text-red' : 'text-green'}`}>{item[coinFields?.percentage] ? FormatToEightDecimals(item[coinFields?.percentage]) : 0}%</Text>}
                                </div>}
                                <div className={` ${isNetwork === true}`}>
                                    <Text className={`coin-val d-block`}>{item[coinFields?.networkType]}</Text>
                                </div>
                            </Skeleton>
                        </List.Item>
                    )}
                />
            }

        </div>
    )
}

const connectStateToProps = ({ userConfig, oidc, WithdrawData }) => {
    return { userConfig: userConfig.userProfileInfo, user: oidc.user, selectedWithdrawCoin: WithdrawData.selectedWDCoin }
}
CoinsList.defaultProps = {
    coinList: [],
};

export default connect(connectStateToProps)(CoinsList);