const { connect } = require("react-redux")

const mapStateToProps = ({ oidc, userConfig   }) => {
    return { profile: oidc?.profile, user: oidc?.user,userProfile:userConfig.userProfileInfo }
}
const mapDispatchToProps = dispatch => {
    return { dispatch }
}
const ConnectStateProps = connect(mapStateToProps, mapDispatchToProps);


export default ConnectStateProps;