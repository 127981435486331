import {publishTransactionRefresh} from '../utils/pubSub';
const SET_NOTIF_COUNT = "setNotificationCount";
const HANDLE_NOTICES = "handleNotices";


const setNotificationCount = (payload) => {
    return {
        type: SET_NOTIF_COUNT,
        payload
    }
}
const fetchDashboardcalls = () => {
    return async () => {
        publishTransactionRefresh();
    } 
  }    

let initialState = {
    notificationCount: 0,
    notices: { loading: false, data: [] },
}

const dashboardReducer = (state , action) => {
    state = state || initialState
    switch (action.type) {
        case HANDLE_NOTICES:
            return { ...state, notices: {data: action.payload.data,  loading: action.payload.loading } }
        case SET_NOTIF_COUNT:
            let _count = action.payload
            if (action?.payload <= 0) {
                _count = 0;
            }
            
            state = { ...state, notificationCount:_count} ;
            return state;
        default:
            return state;
    }
}
export default dashboardReducer;
export {setNotificationCount,fetchDashboardcalls}
